var render = function render(){
  var _vm$publisherCampaign, _vm$publisherCampaign2, _vm$publisherCampaign3, _vm$publisherCampaign4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "max-width": "45rem",
      "margin": "auto"
    }
  }, [_vm.me ? _c('QuarantineCampaignAlert', {
    attrs: {
      "publisher": _vm.me
    }
  }) : _vm._e(), !_vm.publisherCampaign ? _c('div', [_c('div', {
    staticClass: "p-8 text-center"
  }, [_vm.$apollo.queries.publisherCampaign.loading ? [_c('Spinner', {
    attrs: {
      "color": "green",
      "size": 10
    }
  })] : [_c('span', {
    staticClass: "text-gray-500"
  }, [_vm._v("Campaign Not Found")])]], 2)]) : _vm._e(), _vm.publisherCampaign ? _c('div', [_c('portal', {
    attrs: {
      "to": "top-bar-left-header"
    }
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_vm.publisherCampaign.legacyImageThumbnailUrl ? _c('YImage', {
    directives: [{
      name: "img",
      rawName: "v-img",
      value: {
        title: _vm.publisherCampaign.name,
        src: _vm.publisherCampaign.legacyImageUrl
      },
      expression: "{\n            title: publisherCampaign.name,\n            src: publisherCampaign.legacyImageUrl\n          }"
    }],
    staticClass: "rounded block mr-2",
    attrs: {
      "src": _vm.publisherCampaign.legacyImageThumbnailUrl,
      "width": 12,
      "height": 12
    }
  }) : _vm._e(), _vm.publisherCampaign.images.length > 0 ? _c('YImage', {
    directives: [{
      name: "img",
      rawName: "v-img",
      value: {
        title: _vm.publisherCampaign.name,
        src: (_vm$publisherCampaign = _vm.publisherCampaign.images[0]) === null || _vm$publisherCampaign === void 0 ? void 0 : _vm$publisherCampaign.storageUrl
      },
      expression: "{\n            title: publisherCampaign.name,\n            src: publisherCampaign.images[0]?.storageUrl\n          }"
    }],
    staticClass: "rounded block mr-2",
    attrs: {
      "src": (_vm$publisherCampaign2 = _vm.publisherCampaign.images[0]) === null || _vm$publisherCampaign2 === void 0 ? void 0 : _vm$publisherCampaign2.conversions.filter(function (image) {
        return image.conversion === 'thumb';
      })[0].storageUrl,
      "width": 12,
      "height": 12
    }
  }) : _vm._e(), _c('div', [_vm._v(" " + _vm._s(_vm.publisherCampaign.name) + " ")])], 1)]), _c('portal', {
    attrs: {
      "to": "top-bar-secondary"
    }
  }, [_vm.publisherCampaign.status == 'apply to run' && _vm.me && _vm.me.userDetails && _vm.termsAcceptedShowWidget ? _c('YAlert', {
    staticClass: "mb-0 mt-0 px-6",
    attrs: {
      "allowClose": false
    }
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('Icon', {
    staticClass: "mr-2 flex-shrink opacity-50",
    attrs: {
      "name": "alertCircleOutline",
      "size": 6
    }
  }), _c('div', {
    staticClass: "flex-grow"
  }, [_vm._v(" This campaign requires manual approval. ")]), _c('div', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: `You have reached your campaign subscribe limit this month.`,
      expression: "\n              `You have reached your campaign subscribe limit this month.`\n            "
    }],
    staticClass: "flex-shrink"
  }, [_c('YButton', {
    attrs: {
      "color": "blue",
      "disabled": _vm.campaignSubscribeLimitReached
    },
    on: {
      "click": _vm.openRequestApprovalModal
    }
  }, [_vm._v("Request approval")])], 1)], 1)]) : _vm._e(), _vm.publisherCampaign.status == 'pending approval' ? _c('YAlert', {
    staticClass: "mb-0 mt-0 px-6",
    attrs: {
      "allow-close": false
    }
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('Icon', {
    staticClass: "mr-2 flex-shrink opacity-50",
    attrs: {
      "name": "progressCheck",
      "size": 6
    }
  }), _vm._v(" This campaign is currently pending approval. ")], 1)]) : _vm._e()], 1), _vm.publisherCampaign.legacyImageUrl ? _c('div', {
    staticClass: "mb-5 block sm:hidden"
  }, [_c('div', {
    directives: [{
      name: "img",
      rawName: "v-img",
      value: {
        title: _vm.publisherCampaign.name,
        src: _vm.publisherCampaign.legacyImageUrl
      },
      expression: "{\n          title: publisherCampaign.name,\n          src: publisherCampaign.legacyImageUrl\n        }"
    }],
    staticClass: "rounded",
    style: `width: 100%; height: 200px; background-image: url('${_vm.publisherCampaign.legacyImageUrl}'); background-size: cover; background-position: center;`
  })]) : _vm._e(), _c('div', {
    staticClass: "flex items-start gap-2"
  }, [_vm.publisherCampaign.legacyImageUrl ? _c('div', {
    staticClass: "mr-5 mb-0 hidden sm:block"
  }, [_vm.publisherCampaign.legacyImageUrl ? _c('div', {
    directives: [{
      name: "img",
      rawName: "v-img",
      value: {
        title: _vm.publisherCampaign.name,
        src: `${_vm.publisherCampaign.legacyImageUrl}`
      },
      expression: "{\n            title: publisherCampaign.name,\n            src: `${publisherCampaign.legacyImageUrl}`\n          }"
    }],
    staticClass: "rounded-md w-64 h-48",
    style: ` background-image: url('${_vm.publisherCampaign.legacyImageUrl}'); background-size: cover; background-position: center;`
  }) : _vm._e()]) : _vm._e(), _vm.publisherCampaign.images.length > 0 ? _c('div', {
    directives: [{
      name: "img",
      rawName: "v-img",
      value: {
        title: _vm.publisherCampaign.name,
        src: `${_vm.publisherCampaign.images[0].conversions.filter(function (image) {
          return image.conversion === 'thumb';
        })[0].storageUrl}`
      },
      expression: "{\n          title: publisherCampaign.name,\n          src: `${\n            publisherCampaign.images[0].conversions.filter(\n              image => image.conversion === 'thumb'\n            )[0].storageUrl\n          }`\n        }"
    }],
    staticClass: "rounded-md w-64 h-48",
    style: ` background-image: url('${_vm.publisherCampaign.images[0].conversions.filter(function (image) {
      return image.conversion === 'thumb';
    })[0].storageUrl}'); background-size: contain; background-position: center;`
  }) : _vm._e(), _c('div', [_c('h4', {
    staticClass: "mb-1"
  }, [_c('span', {
    staticClass: "pr-2 text-base font-bold"
  }, [_vm._v(_vm._s(_vm.publisherCampaign.name))]), _c('sup', {
    staticClass: "text-gray-600 text-sm"
  }, [_vm._v(_vm._s(_vm.publisherCampaign.id))])]), _c('div', {
    staticClass: "pb-2"
  }, [_vm._l(_vm.publisherCampaign.verticalTags, function (tag) {
    return _c('div', {
      key: tag.id,
      staticClass: "mr-1 mb-1 inline-flex"
    }, [_c('Tag', {
      attrs: {
        "color": tag.color
      }
    }, [_vm._v(_vm._s(tag.name))])], 1);
  }), _vm.publisherCampaign.isCapped ? _c('Tag', {
    staticClass: "mr-1 mb-1",
    attrs: {
      "color": "darkRed"
    }
  }, [_vm._v("CAPPED")]) : _vm._e()], 2), _vm.campaignTopGeos && _vm.campaignTopGeos.length > 0 || _vm.$apollo.queries.campaignTopGeos.loading ? _c('div', {
    staticClass: "inline-flex pb-2 items-center"
  }, [_vm.campaignTopGeos && _vm.campaignTopGeos.length > 0 ? [_c('div', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: `Top Geos`,
      expression: "`Top Geos`"
    }],
    staticClass: "text-xl leading-3 font-semibold text-gray-600 pr-3"
  }, [_vm._v(" 🏆 ")]), _c('div', {
    staticClass: "inline-flex"
  }, _vm._l(_vm.campaignTopGeos, function (country) {
    return _c('Flag', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: `${country.label}`,
        expression: "`${country.label}`"
      }],
      key: country.code,
      staticClass: "m-px rounded",
      attrs: {
        "country": country.code
      }
    });
  }), 1)] : _vm.$apollo.queries.campaignTopGeos.loading ? [_c('Spinner', {
    attrs: {
      "size": 4,
      "color": "gray"
    }
  })] : _vm._e()], 2) : _vm._e(), _c('div', {
    staticClass: "mt-1 mb-1"
  }, [_vm.publisherCampaign.redirect && _vm.publisherCampaign.redirect.status == 'subscribed' ? [_vm.publisherCampaign.caps ? _vm._l(_vm.publisherCampaign.caps, function (cap) {
    return _c('div', {
      key: cap.id,
      staticClass: "mb-2"
    }, [_c('span', {
      staticClass: "font-semibold"
    }, [_vm._v(" " + _vm._s(cap.frequency.capitalize()) + " Cap: ")]), _vm._v(" " + _vm._s(_vm.capProgress(cap)) + " "), _c('ProgressBar', {
      attrs: {
        "bar-color": cap.isCapped ? '#f56565' : '#48bb78',
        "val": _vm.capProgressBarValue(cap),
        "size": "small",
        "bar-border-radius": 5
      }
    })], 1);
  }) : _vm._e()] : _vm.publisherCampaign.capDefaults ? _vm._l(_vm.publisherCampaign.capDefaults, function (capDefault) {
    return _c('div', {
      key: capDefault.id,
      staticClass: "font-semibold mb-2"
    }, [_vm._v(" " + _vm._s(_vm.formatCapToString(capDefault)) + " ")]);
  }) : _vm._e()], 2), _c('div', {
    staticClass: "mt-4 mb-3 campaign-description",
    domProps: {
      "innerHTML": _vm._s(_vm.publisherCampaign.description)
    }
  })])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isExpanded == true,
      expression: "isExpanded == true"
    }],
    staticClass: "mt-8 py-2"
  }, [[_c('div', {
    staticClass: "mb-4"
  }, [_c('YTable', {
    staticClass: "table table-sm w-full",
    attrs: {
      "data": _vm.visiblePayouts || [],
      "scroll-x": false
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        var _props$rowData$countr;
        return [_c('td', [((_props$rowData$countr = props.rowData.country) === null || _props$rowData$countr === void 0 ? void 0 : _props$rowData$countr.code) == null ? [_vm._v(" All Countries ")] : [_vm._v(" " + _vm._s(props.rowData.country.code) + " - " + _vm._s(props.rowData.country.name) + " ")]], 2), _c('td', [_vm._v(" " + _vm._s(props.rowData.rate ? props.rowData.rate + ' / ' : '') + " "), props.rowData.prettyType == 'CPA' ? [_vm._v(" " + _vm._s(_vm.publisherCampaign.payoutType) + " ")] : [_vm._v(" " + _vm._s(props.rowData.prettyType) + " ")]], 2)];
      }
    }], null, false, 1438111256)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Geo")]), _c('th', [_vm._v("Rate")])])], 2), _vm.publisherCampaign.payouts.length > _vm.showPayoutsLength ? _c('div', {
    staticClass: "py-1"
  }, [_c('div', {
    staticClass: "flex justify-between items-center"
  }, [_vm.payoutsAreExpanded ? [_c('a', {
    staticClass: "link",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.togglePayoutsAreExpanded.apply(null, arguments);
      }
    }
  }, [_vm._v("Collapse")])] : [_c('div', {
    staticClass: "flex items-center"
  }, [_c('span', {
    staticClass: "text-gray-600 mr-4"
  }, [_vm._v("+ " + _vm._s(_vm.invisiblePayoutsLength) + " more geos")]), _c('a', {
    staticClass: "link block",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.togglePayoutsAreExpanded.apply(null, arguments);
      }
    }
  }, [_vm._v("View All")])])], _c('div', [_c('a', {
    staticClass: "link",
    on: {
      "click": _vm.exportPayouts
    }
  }, [_vm._v("Export Payouts")])])], 2)]) : _vm._e()], 1), _c('div', {
    staticClass: "flex flex-row gap-4"
  }, [_c('Widget', {
    staticClass: "mb-4 overflow-hidden flex-1"
  }, [_c('WidgetHeader', {
    staticClass: "bg-gray-100 border-b-2 border-gray-200 py-3"
  }, [_c('span', {
    staticClass: "text-xs font-semibold text-gray-600"
  }, [_vm._v("ALLOWED")])]), _c('WidgetBody', {
    staticClass: "p-0"
  }, [_c('div', {
    staticClass: "grid grid-col-5 grid-flow-col"
  }, [_c('div', {
    staticClass: "row-span-4 col-span-3 border-r-2 border-gray-200 p-4"
  }, [_c('span', {
    staticClass: "block font-bold text-gray-700 pb-2"
  }, [_vm._v("Traffic Type ")]), _vm._l(_vm.publisherCampaign.allowedRestrictedTags.filter(function (tag) {
    return !tag.isNot;
  }), function (tag) {
    return _c('Tag', {
      key: tag.name,
      staticClass: "mr-1 mb-2",
      attrs: {
        "color": 'green'
      }
    }, [_vm._v(_vm._s(tag.name))]);
  })], 2), _c('div', {
    staticClass: "col-span-1 border-b-2 border-gray-200 p-4"
  }, [_c('span', {
    staticClass: "block font-bold text-gray-700 pb-2"
  }, [_vm._v("Browser Type ")]), _vm._l(_vm.publisherCampaign.campaignBrowser, function (tag) {
    return _c('Tag', {
      key: tag.name,
      staticClass: "mr-1 mb-2",
      attrs: {
        "color": 'orange'
      }
    }, [_vm._v(_vm._s(tag.name))]);
  })], 2), _c('div', {
    staticClass: "row-span-2 p-4"
  }, [_c('span', {
    staticClass: "block font-bold text-gray-700 pb-2"
  }, [_vm._v("OS Type ")]), _vm._l(_vm.publisherCampaign.campaignOS, function (tag) {
    return _c('Tag', {
      key: tag.name,
      staticClass: "mr-1 mb-2",
      attrs: {
        "color": 'orange'
      }
    }, [_vm._v(_vm._s(tag.name))]);
  })], 2)])])], 1), _c('Widget', {
    staticClass: "mb-4 overflow-hidden w-1/3"
  }, [_c('WidgetHeader', {
    staticClass: "bg-gray-100 border-b-2 border-gray-200 py-3"
  }, [_c('span', {
    staticClass: "text-xs font-semibold text-gray-600"
  }, [_vm._v("RESTRICTED")])]), _c('WidgetBody', _vm._l(_vm.publisherCampaign.allowedRestrictedTags.filter(function (tag) {
    return tag.isNot;
  }), function (tag) {
    return _c('Tag', {
      key: tag.name,
      staticClass: "mr-1 mb-2",
      attrs: {
        "color": 'red'
      }
    }, [_vm._v(_vm._s(tag.name))]);
  }), 1)], 1)], 1), _vm.termsAcceptedShowWidget ? _c('Widget', {
    staticClass: "mb-4 overflow-hidden",
    attrs: {
      "disabled": ((_vm$publisherCampaign3 = _vm.publisherCampaign) === null || _vm$publisherCampaign3 === void 0 ? void 0 : (_vm$publisherCampaign4 = _vm$publisherCampaign3.redirect) === null || _vm$publisherCampaign4 === void 0 ? void 0 : _vm$publisherCampaign4.status) != 'subscribed' && _vm.campaignSubscribeLimitReached
    },
    scopedSlots: _vm._u([{
      key: "disabledOverlay",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center pt-6"
        }, [_c('strong', {
          staticClass: "text-orange-600"
        }, [_vm._v("Your campaign subscription limit of 3 has been reached for this month.")])])];
      },
      proxy: true
    }], null, false, 416351722)
  }, [_c('WidgetHeader', {
    staticClass: "bg-gray-100 border-b-2 border-gray-200 py-3",
    scopedSlots: _vm._u([{
      key: "rawContent",
      fn: function () {
        return [_c('div', [_c('div', {
          staticClass: "mb-2"
        }, [_c('span', {
          staticClass: "text-xs font-semibold text-gray-600"
        }, [_vm._v("TRACKING LINKS & PARAMETERS")])]), _c('RedirectParameters', {
          attrs: {
            "user": _vm.me,
            "layout": "inline",
            "disable-saving": true
          },
          on: {
            "value": _vm.updateRedirectParameters
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 3300834399)
  }), _vm.publisherCampaign.redirect && _vm.publisherCampaign.redirect.status == 'unsubscribed' ? _c('WidgetBody', [_c('div', [_c('p', [_vm._v(" Your account has been "), _c('span', {
    staticClass: "font-bold text-pink-700"
  }, [_vm._v("unsubscribed")]), _vm._v(" from this campaign. Please contact your account manager for more information. ")])])]) : _c('WidgetBody', [_vm.publisherCampaign.offers && _vm.publisherCampaign.offers.length > 0 ? _c('div', _vm._l(_vm.publisherCampaign.offers, function (offer) {
    return _c('div', {
      key: offer.id,
      staticClass: "flex items-center mb-2"
    }, [_c('div', {
      staticClass: "mr-4 w-20 h-20"
    }, [_c('YImage', {
      directives: [{
        name: "img",
        rawName: "v-img",
        value: {
          title: _vm.publisherCampaign.name,
          src: offer.legacyImageUrl
        },
        expression: "{\n                      title: publisherCampaign.name,\n                      src: offer.legacyImageUrl\n                    }"
      }],
      staticClass: "rounded w-20 h-20 block",
      attrs: {
        "src": offer.legacyImageThumbnailUrl,
        "width": 20,
        "height": 20
      }
    })], 1), _c('div', [_c('p', {
      staticClass: "mb-1 fw-500"
    }, [offer.destinationType == 'direct' || offer.template && offer.template.path == 'redirect' ? [_vm._v(" Direct ")] : offer.template ? [_vm._v(" " + _vm._s(offer.template.name ? offer.template.name : 'Missing Name') + " ")] : _vm._e()], 2), _vm.publisherCampaign.redirect && _vm.publisherCampaign.redirect.status == 'subscribed' ? _c('div', [_c('a', {
      staticClass: "link without-truncate break-all",
      attrs: {
        "href": `${_vm.publisherCampaign.redirect.url}/o/${offer.encodedId}${_vm.redirectParametersString}`,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(_vm.publisherCampaign.redirect.url) + "/o/" + _vm._s(offer.encodedId) + _vm._s(_vm.redirectParametersString))])]) : _vm._e()])]);
  }), 0) : _vm.publisherCampaign.redirect && _vm.publisherCampaign.redirect.status == 'subscribed' ? _c('div', {
    staticClass: "flex items-center mb-2"
  }, [_c('div', [_c('div', {
    staticClass: "font-semibold text-gray-600"
  }, [_vm._v("Your Link")]), _c('a', {
    staticClass: "link without-truncate break-all",
    attrs: {
      "href": `${_vm.publisherCampaign.redirect.url}/${_vm.redirectParametersString}`,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.publisherCampaign.redirect.url) + "/" + _vm._s(_vm.redirectParametersString))])])]) : _vm._e(), _vm.publisherCampaign.status == 'available' && (!_vm.publisherCampaign.redirect || _vm.publisherCampaign.redirect && !_vm.publisherCampaign.redirect.status == 'approved') ? _c('div', [_c('p', {
    staticClass: "mb-2"
  }, [_c('span', {
    staticClass: "font-semibold"
  }, [_vm._v("Agree to the terms below to generate your tracking links:")])]), _c('YAlert', {
    attrs: {
      "allow-close": false
    }
  }, [_c('div', {
    staticClass: "flex items-center justify-between"
  }, [_c('label', [_c('YCheckbox', {
    model: {
      value: _vm.agreedToAllowed,
      callback: function ($$v) {
        _vm.agreedToAllowed = $$v;
      },
      expression: "agreedToAllowed"
    }
  }), _vm._v(" I acknowledge and agree that I will only send the \"Allowed\" traffic listed above. ")], 1), _c('YButton', {
    attrs: {
      "color": "blue",
      "disabled": !_vm.agreedToAllowed || _vm.campaignSubscribeLimitReached,
      "is-loading": _vm.isAgreeing
    },
    on: {
      "click": _vm.agreeToAllowedTraffic
    }
  }, [_vm._v("I Agree")])], 1)])], 1) : _vm._e()])], 1) : _vm._e(), _vm.publisherCampaign.redirect ? _c('Widget', {
    staticClass: "mb-4 overflow-hidden"
  }, [_c('WidgetHeader', {
    staticClass: "bg-gray-100 border-b-2 border-gray-200 py-3"
  }, [_c('span', {
    staticClass: "text-xs font-semibold text-gray-600"
  }, [_vm._v("YOUR POSTBACKS")])]), _c('WidgetBody', [_c('YAlert', {
    staticClass: "mt-0 mb-6"
  }, [_vm._v(" This campaign specific postback will override an "), _c('router-link', {
    staticClass: "link font-semibold",
    attrs: {
      "to": {
        name: 'Publisher Postbacks'
      }
    }
  }, [_vm._v("account level postback")]), _vm._v(". ")], 1), _vm.publisherCampaign.redirect.postbacks ? _c('div', {
    staticClass: "my-4"
  }, [_c('YTable', {
    attrs: {
      "data": _vm.publisherCampaign.redirect.postbacks
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        var _props$rowData$conver, _props$rowData$conver2;
        return [_c('td', [_vm._v(_vm._s(props.rowData.url))]), _c('td', [_c('div', {
          staticClass: "cursor-pointer"
        }, [_c('v-popover', {
          attrs: {
            "offset": "10",
            "placement": "top",
            "trigger": "hover"
          }
        }, [_c('div', {
          staticClass: "flex align-center"
        }, [props.rowData.conversionType ? _c('Tag', {
          attrs: {
            "color": "blue"
          }
        }, [_vm._v(_vm._s(props.rowData.conversionType.name))]) : _c('Tag', {
          attrs: {
            "color": "orange"
          }
        }, [_vm._v("Default")]), _c('Icon', {
          staticClass: "ml-1 text-gray-500 self-center",
          attrs: {
            "name": "information",
            "size": "4"
          }
        })], 1), _c('template', {
          slot: "popover"
        }, [_c('div', {
          staticClass: "bg-gray-100 rounded-md p-4"
        }, [_c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.conversionTypeDescription((_props$rowData$conver = (_props$rowData$conver2 = props.rowData.conversionType) === null || _props$rowData$conver2 === void 0 ? void 0 : _props$rowData$conver2.name) !== null && _props$rowData$conver !== void 0 ? _props$rowData$conver : 'Default'))
          }
        })])])], 2)], 1)]), _c('td', [_c('div', {
          staticClass: "flex justify-end"
        }, [_c('a', {
          staticClass: "link p-1",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openPostbackEditModal(props.rowData);
            }
          }
        }, [_c('Icon', {
          attrs: {
            "name": "pencil",
            "size": 5
          }
        })], 1), _c('a', {
          staticClass: "text-red-500 hover:text-red-600 p-1",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openPostbackDeleteModal(props.rowData);
            }
          }
        }, [_c('Icon', {
          attrs: {
            "name": "trashCan",
            "size": 5
          }
        })], 1)])])];
      }
    }], null, false, 3319115492)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("url")]), _c('th', [_vm._v(" Conversion Type ")]), _c('th')])], 2)], 1) : _vm._e(), _c('div', {
    staticClass: "py-1 flex justify-between"
  }, [_c('YButton', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.hasDefaultPostback,
      expression: "!hasDefaultPostback"
    }],
    staticClass: "btn-link w-full",
    attrs: {
      "disabled": _vm.hasDefaultPostback
    },
    on: {
      "click": function ($event) {
        return _vm.openCreatePostbackModal();
      }
    }
  }, [_vm._v("Add Campaign Postback")])], 1)], 1)], 1) : _vm._e()]], 2)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }