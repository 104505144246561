<template>
  <div v-if="campaign" class="pt-4 w-100 max-w-3xl">
    <div class="x-row">
      <div class="x-col1">Image</div>
      <div class="x-col2 flex bg-green-500flex-row gap-2">
        <div v-if="!displayImageFromRepository">
          <EditableImage
            class="rounded hover:cursor-default"
            :imageUrl="campaign.legacyImageUrl"
            :height="24"
            :width="24"
            modelClass="Campaign"
            :modelId="campaign.id"
            :deleteExisting="true"
            :editable="false"
          ></EditableImage>
          <p class="w-32 text-xs text-gray-600 leading-tight">
            New images must be selected from repository
          </p>
        </div>

        <div class="flex flex-row gap-2 w-full">
          <Superselect
            title="Select Image"
            v-model="imageFile"
            :options="[]"
            track-by="allImageData"
            label="name"
            :multiple="false"
            no-result="Sorry, no images found."
            :query="IMAGES_QUERY"
            :query-variables="{
              first: 10,
              filters: {
                name: {
                  value: ['{input}'],
                  modifiers: { matchType: 'contains' }
                }
              }
            }"
            class="flex-grow"
          >
            <template v-slot:singleLabel>
              <div class="flex items-center">
                <div class="rounded w-24 overflow-hidden flex justify-center">
                  <YImage
                    :src="imageFile.imageThumbnailUrl"
                    :width="null"
                    :height="24"
                    class="rounded object-cover h-full m-1"
                  />
                </div>
                <div class="ml-2">{{ imageFile.name }}</div>
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <div class="flex items-center -mx-2">
                <YImage
                  :src="props.option.imageThumbnailUrl"
                  :width="null"
                  :height="16"
                  class="rounded"
                />
                <div class="ml-2">{{ props.option.name }}</div>
              </div>
            </template></Superselect
          >
          <div v-if="imageHasUnsavedChanges">
            <span
              class="inline-flex items-center rounded-lg shadow-md ml-2 overflow-hidden"
            >
              <span
                ref="saveAction"
                tabindex="0"
                class="cursor-pointer px-1 bg-green-300 hover:bg-green-400 focus:bg-green-400 text-green-700 outline-none-important"
                @click="saveImage"
                @keydown.enter="saveImage"
                ><Icon name="contentSave"
              /></span>
              <span
                tabindex="0"
                class="cursor-pointer bg-red-300 hover:bg-red-400 focus:bg-red-400 text-red-700 px-1 outline-none-important"
                @click="cancelImage"
                @keydown.enter="saveImage"
                ><Icon name="close"
              /></span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="x-row">
      <div class="x-col1" @click="$refs.smartLinkEditable.toggleCheckbox()">
        Smart Link
      </div>
      <div class="x-col2">
        <EditableValue
          ref="smartLinkEditable"
          :value="campaign.smartLink"
          :mutation="UPDATE_CAMPAIGN_MUTATION"
          type="checkbox"
          :variables="{
            input: {
              id: campaign.id,
              smartLink: '{value}'
            }
          }"
        ></EditableValue>
      </div>
    </div>
    <div v-if="campaign.smartLink == 1" class="x-row">
      <div class="x-col1" @click="$refs.smartLinkEditable.toggleCheckbox()">
        Prefered Algorithm
      </div>
      <div class="x-col2">
        <div class="x-col2">
          <EditableValue
            ref="prefferedAlgorithmEditable"
            :value="campaign.preferredAlgorithmId"
            type="slot"
            :mutation="UPDATE_CAMPAIGN_MUTATION"
            :variables="{
              input: {
                id: campaign.id,
                preferredAlgorithmId: '{value}'
              }
            }"
          >
            <template v-slot:default="slotProps">
              <div v-if="slotProps.value && slotProps.value.length > 0">
                <Tag
                  v-for="tag in slotProps.value"
                  :key="tag"
                  class="mr-1 my-1"
                  tabindex="0"
                  @focus="
                    () => {
                      slotProps.focus()
                    }
                  "
                  >{{ tag }}</Tag
                >
              </div>
              <div
                v-else
                @click="
                  () => {
                    slotProps.focus()
                  }
                "
              >
                <span
                  class="italic text-gray-500 hover:bg-blue-200 cursor-pointer"
                  >None</span
                >
              </div>
            </template>
            <template v-slot:focusSlot="slotProps">
              <Superselect
                title="Algorithm Id"
                :value="slotProps.value"
                @input="
                  value => {
                    slotProps.updateInternalValue(value)
                  }
                "
                :focusOnMount="true"
                @close="() => {}"
                :multiple="false"
                placeholder="Select"
                class="box-width min-width-full"
                :options="['2', '7']"
              ></Superselect>
            </template>
          </EditableValue>
        </div>
      </div>
    </div>
    <div class="x-row">
      <div class="x-col1" @click="$refs.featuredEditable.toggleCheckbox()">
        Featured
      </div>
      <div class="x-col2">
        <EditableValue
          ref="featuredEditable"
          :value="campaign.isFeatured"
          :mutation="UPDATE_CAMPAIGN_MUTATION"
          type="checkbox"
          :variables="{
            input: {
              id: campaign.id,
              isFeatured: '{value}'
            }
          }"
        ></EditableValue>
      </div>
      <div class="x-col1" @click="$refs.isPinnedEditable.toggleCheckbox()">
        Pinned Campaign
      </div>
      <div class="x-col2">
        <EditableValue
          ref="isPinnedEditable"
          :value="campaign.isPinned"
          :mutation="UPDATE_CAMPAIGN_MUTATION"
          type="checkbox"
          :variables="{
            input: {
              id: campaign.id,
              isPinned: '{value}'
            }
          }"
        ></EditableValue>
      </div>
    </div>
    <div class="x-row">
      <div class="x-col1" @click="$refs.approvalRequiredTypesEditable.focus()">
        Approval Required
      </div>
      <div class="x-col2">
        <EditableValue
          ref="approvalRequiredTypesEditable"
          :value="campaign.approvalRequiredTypes"
          type="slot"
          :mutation="UPDATE_CAMPAIGN_MUTATION"
          :variables="{
            input: {
              id: campaign.id,
              approvalRequiredTypes: '{value}'
            }
          }"
        >
          <template v-slot:default="slotProps">
            <div v-if="slotProps.value && slotProps.value.length > 0">
              <Tag
                v-for="tag in slotProps.value"
                :key="tag"
                class="mr-1 my-1"
                :color="tag"
                tabindex="0"
                @focus="
                  () => {
                    slotProps.focus()
                  }
                "
                >{{ tag }}</Tag
              >
            </div>
            <div
              v-else
              @click="
                () => {
                  slotProps.focus()
                }
              "
            >
              <span
                class="italic text-gray-500 hover:bg-blue-200 cursor-pointer"
                >None</span
              >
            </div>
          </template>
          <template v-slot:focusSlot="slotProps">
            <Superselect
              title="Approval Required"
              :value="slotProps.value"
              @input="
                value => {
                  slotProps.updateInternalValue(value)
                }
              "
              @close="
                () => {
                  //slotProps.unfocusOnNoChanges() || slotProps.focusOnSave()
                }
              "
              :focusOnMount="true"
              :multiple="true"
              placeholder="Select"
              style="min-width: 10rem"
              :options="[
                'all',
                'quarantined',
                'quality',
                'fraud',
                'compliance'
              ]"
            ></Superselect>
          </template>
        </EditableValue>
      </div>
    </div>
    <div class="x-row">
      <div class="x-col1" @click="openPermissionsModal">Access Rules</div>
      <div class="x-col2">
        <template v-if="campaign.publisherAbilitySummary">
          <div class="box-width">
            <div
              class="overflow-y-scroll rounded cursor-pointer hover:shadow-md focus:shadow-md outline-none"
              style="max-height: 8rem"
              @click="openPermissionsModal"
              @keydown.enter="openPermissionsModal"
              tabindex="0"
            >
              <Tag
                v-if="campaign.publisherAbilitySummary.all"
                color="gray"
                :shade="100"
                class="block py-2 rounded-none"
                >All Publishers</Tag
              >
              <Tag
                v-for="only of campaign.publisherAbilitySummary.only"
                color="blue"
                :shade="100"
                :key="only.id"
                class="block py-2 rounded-none"
                >{{ only.label }}</Tag
              >

              <Tag
                v-for="except of campaign.publisherAbilitySummary.except"
                color="blue"
                :shade="100"
                :key="except.id"
                class="block py-2 rounded-none bg-red-100 text-red-600"
                >{{ except.label }}</Tag
              >
            </div>
            <div class="text-right">
              <small
                v-if="campaign.publisherAbilitySummary.except.length"
                class="font-semibold text-red-600"
              >
                {{ campaign.publisherAbilitySummary.except.length }}
                Excluded
              </small>
              <small
                v-if="campaign.publisherAbilitySummary.only.length"
                class="font-semibold text-blue-600"
                >{{ campaign.publisherAbilitySummary.only.length }} Only</small
              >
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="x-row">
      <div class="x-col1">Subscribers</div>
      <div class="x-col2">
        <template v-if="campaign && campaign.subscribers">
          <div class="box-width">
            <div
              class="overflow-y-scroll rounded cursor-pointer hover:shadow-md focus:shadow-md outline-none"
              style="max-height: 8rem"
            >
              <router-link
                v-for="user of campaign.subscribers"
                :to="{
                  name: 'User',
                  params: { id: user.id }
                }"
                class="link truncate block hover:underline"
                :key="user.id"
              >
                <Tag
                  color="blue"
                  :shade="100"
                  class="block py-2 rounded-none"
                  :hover="true"
                  >{{ user.label }}</Tag
                >
              </router-link>
            </div>
            <div class="flex justify-between">
              <div>
                <small>
                  <a
                    href="#"
                    class="link cursor-pointer mt-1 block font-semibold"
                    @click.prevent="openSubscribeUserModal"
                    tabindex="0"
                    >Add Subscribers</a
                  >
                </small>
              </div>
              <div>
                <small
                  v-if="campaign.subscribers"
                  class="font-semibold text-gray-700 text-blue-600 hover:text-blue-500 cursor-pointer"
                  @click.prevent="openSubscribeUserModal"
                >
                  {{ campaign.subscribers.length }} Subscriber<template
                    v-if="campaign.subscribers.length > 1"
                    >s</template
                  >
                </small>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="x-row">
      <div
        class="x-col1"
        @click="
          $router.push({ name: 'Campaign', params: { id: id, tab: 'offers' } })
        "
      >
        Advertisers
      </div>
      <div class="x-col2">
        <div class="box-width">
          <div
            class="overflow-y-scroll rounded cursor-pointer hover:shadow-md focus:shadow-md outline-none"
            style="max-height: 8rem"
          >
            <router-link
              v-for="user of advertisers"
              :to="{
                name: 'User',
                params: { id: user.id }
              }"
              class="link truncate block hover:underline"
              :key="user.id"
            >
              <Tag
                color="blue"
                :shade="100"
                class="block py-2 rounded-none"
                :hover="true"
                >{{ user.label }}</Tag
              >
            </router-link>
          </div>
          <div class="flex justify-between">
            <div></div>
            <div>
              <small
                v-if="campaign.offers"
                @click.prevent="
                  $router.push({
                    name: 'Campaign',
                    params: { id: id, tab: 'offers' }
                  })
                "
                class="font-semibold text-gray-700 text-blue-600 hover:text-blue-700 cursor-pointer"
              >
                {{ advertisers.length }} Advertiser<template
                  v-if="advertisers.length > 1"
                  >s</template
                >
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="x-row">
      <div class="x-col1" @click="$refs.verticalsEditable.focus()">
        Verticals
      </div>
      <div class="x-col2">
        <EditableValue
          ref="verticalsEditable"
          :value="campaign.tags.filter(tag => tag.type === 'vertical')"
          type="slot"
          :mutation="SYNC_TAGS_WITH_TYPE_MUTATION"
          sortBy="id"
          :transformValueForQuery="
            value => {
              return value.map(tag => tag.name)
            }
          "
          :variables="{
            input: {
              type: 'vertical',
              taggableType: 'campaign',
              taggableId: campaign.id,
              tags: '{value}'
            }
          }"
        >
          <template v-slot:default="slotProps">
            <div v-if="slotProps.value && slotProps.value.length > 0">
              <Tag
                v-for="tag in slotProps.value"
                :key="tag.id"
                class="mr-1 my-1"
                :color="tag.color"
                tabindex="0"
                @focus="
                  () => {
                    slotProps.focus()
                  }
                "
                >{{ tag.name }}</Tag
              >
            </div>
            <div
              v-else
              @click="
                () => {
                  slotProps.focus()
                }
              "
            >
              <span
                class="italic text-gray-500 hover:bg-blue-200 cursor-pointer"
                >None</span
              >
            </div>
          </template>
          <template v-slot:focusSlot="slotProps">
            <Superselect
              title="Verticals"
              :value="slotProps.value[0]"
              @input="
                value => {
                  slotProps.updateInternalValue([value])
                }
              "
              @close="
                () => {
                  //slotProps.unfocusOnNoChanges() || slotProps.focusOnSave()
                }
              "
              :focusOnMount="true"
              :multiple="false"
              track-by="slug"
              label="name"
              placeholder="Select"
              style="min-width: 10rem"
              :query="TAGS_QUERY"
              :query-variables="{
                filters: {
                  type: { value: ['vertical'] }
                },
                first: 100
              }"
            ></Superselect>
          </template>
        </EditableValue>
      </div>
    </div>

    <div class="flex items-center pt-4 pb-1">
      <div class="x-col1" @click="$refs.tagsEditable.focus()">Browser Type</div>
      <div class="x-col2">
        <EditableValue
          ref="tagsEditable"
          :value="campaign.tags.filter(tag => tag.type === 'campaignBrowser')"
          type="slot"
          :mutation="SYNC_TAGS_WITH_TYPE_MUTATION"
          sortBy="id"
          :transformValueForQuery="
            value => {
              return value.map(tag => tag.name)
            }
          "
          trackBy="type"
          :variables="{
            input: {
              type: 'campaignBrowser',
              taggableType: 'campaign',
              taggableId: campaign.id,
              tags: '{value}'
            }
          }"
        >
          <template v-slot:default="slotProps">
            <div v-if="slotProps.value && slotProps.value.length > 0">
              <Tag
                v-for="tag in slotProps.value"
                :key="tag.id"
                class="mr-1 my-1"
                :color="tag.color"
                tabindex="0"
                @focus="
                  () => {
                    slotProps.focus()
                  }
                "
                >{{ tag.name }}</Tag
              >
            </div>
            <div
              v-else
              @click="
                () => {
                  slotProps.focus()
                }
              "
            >
              <span
                class="italic text-gray-500 hover:bg-blue-200 cursor-pointer"
                >None</span
              >
            </div>
          </template>
          <template v-slot:focusSlot="slotProps">
            <Superselect
              title="Tags"
              :value="slotProps.value"
              @input="
                value => {
                  slotProps.updateInternalValue(value)
                }
              "
              @close="
                () => {
                  //slotProps.unfocusOnNoChanges() || slotProps.focusOnSave()
                }
              "
              :focusOnMount="true"
              :multiple="true"
              track-by="slug"
              label="name"
              placeholder="Select"
              class="box-width min-width-full"
              :query="TAGS_QUERY"
              :query-variables="{
                filters: {
                  type: { value: ['campaignBrowser'] }
                },
                first: 100
              }"
            ></Superselect>
          </template>
        </EditableValue>
      </div>
    </div>

    <div class="flex items-center pt-1 pb-4">
      <div class="x-col1" @click="$refs.tagsEditable.focus()">OS Type</div>
      <div class="x-col2">
        <EditableValue
          ref="tagsEditable"
          :value="campaign.tags.filter(tag => tag.type === 'campaignOS')"
          type="slot"
          :mutation="SYNC_TAGS_WITH_TYPE_MUTATION"
          sortBy="id"
          :transformValueForQuery="
            value => {
              return value.map(tag => tag.name)
            }
          "
          trackBy="type"
          :variables="{
            input: {
              type: 'campaignOS',
              taggableType: 'campaign',
              taggableId: campaign.id,
              tags: '{value}'
            }
          }"
        >
          <template v-slot:default="slotProps">
            <div v-if="slotProps.value && slotProps.value.length > 0">
              <Tag
                v-for="tag in slotProps.value"
                :key="tag.id"
                class="mr-1 my-1"
                :color="tag.color"
                tabindex="0"
                @focus="
                  () => {
                    slotProps.focus()
                  }
                "
                >{{ tag.name }}</Tag
              >
            </div>
            <div
              v-else
              @click="
                () => {
                  slotProps.focus()
                }
              "
            >
              <span
                class="italic text-gray-500 hover:bg-blue-200 cursor-pointer"
                >None</span
              >
            </div>
          </template>
          <template v-slot:focusSlot="slotProps">
            <Superselect
              title="Tags"
              :value="slotProps.value"
              @input="
                value => {
                  slotProps.updateInternalValue(value)
                }
              "
              @close="
                () => {
                  //slotProps.unfocusOnNoChanges() || slotProps.focusOnSave()
                }
              "
              :focusOnMount="true"
              :multiple="true"
              track-by="slug"
              label="name"
              placeholder="Select"
              class="box-width min-width-full"
              :query="TAGS_QUERY"
              :query-variables="{
                filters: {
                  type: { value: ['campaignOS'] }
                },
                first: 100
              }"
            ></Superselect>
          </template>
        </EditableValue>
      </div>
    </div>

    <div class="x-row">
      <div class="x-col1" @click="$refs.allowedTagsEditable.focus()">
        Traffic Type
      </div>
      <div class="x-col2">
        <EditableValue
          ref="allowedTagsEditable"
          class="inline"
          :value="
            campaign.tags.filter(
              tag => tag.type === 'trafficType' && tag.isNot === false
            )
          "
          type="slot"
          :mutation="SYNC_TAGS_WITH_TYPE_MUTATION"
          sortBy="id"
          :transformValueForQuery="
            value => {
              return value.map(tag => tag.name)
            }
          "
          :variables="{
            input: {
              type: 'trafficType',
              taggableType: 'campaign',
              taggableId: campaign.id,
              tags: '{value}',
              isNot: false,
              detachMode: 'SAME_POLARITY'
            }
          }"
        >
          <template v-slot:default="slotProps">
            <div>
              <Tag
                v-for="tag in slotProps.value"
                :key="tag.id"
                class="mr-1 my-1"
                :color="tag.color"
                tabindex="0"
                @focus="
                  () => {
                    slotProps.focus()
                  }
                "
                >{{ tag.name }}</Tag
              >
            </div>
          </template>
          <template v-slot:focusSlot="slotProps">
            <Superselect
              title="Allowed"
              :value="slotProps.value"
              @input="
                value => {
                  slotProps.updateInternalValue(value)
                }
              "
              @close="
                () => {
                  //slotProps.unfocusOnNoChanges() || slotProps.focusOnSave()
                }
              "
              :focusOnMount="true"
              :multiple="true"
              track-by="slug"
              label="name"
              placeholder="Select"
              class="box-width min-width-full"
              :query="TAGS_QUERY"
              :query-variables="{
                filters: {
                  type: { value: ['trafficType'] }
                },
                first: 100
              }"
            ></Superselect>
          </template>
        </EditableValue>
      </div>
    </div>
    <div class="x-row">
      <div class="x-col1" @click="$refs.restrictedTagsEditable.focus()">
        Restricted
      </div>
      <div class="x-col2">
        <EditableValue
          ref="restrictedTagsEditable"
          :value="
            campaign.tags.filter(
              tag => tag.type === 'trafficType' && tag.isNot === true
            )
          "
          type="slot"
          :mutation="SYNC_TAGS_WITH_TYPE_MUTATION"
          sortBy="id"
          :transformValueForQuery="
            value => {
              return value.map(tag => tag.name)
            }
          "
          :variables="{
            input: {
              type: 'trafficType',
              taggableType: 'campaign',
              taggableId: campaign.id,
              tags: '{value}',
              isNot: true,
              detachMode: 'SAME_POLARITY'
            }
          }"
        >
          <template v-slot:default="slotProps">
            <div>
              <Tag
                v-for="tag in slotProps.value"
                :key="tag.id"
                class="mr-1 my-1"
                color="red"
                tabindex="0"
                @focus="
                  () => {
                    slotProps.focus()
                  }
                "
                >{{ tag.name }}</Tag
              >
            </div>
          </template>
          <template v-slot:focusSlot="slotProps">
            <Superselect
              title="Restricted"
              :value="slotProps.value"
              @input="
                value => {
                  slotProps.updateInternalValue(value)
                }
              "
              @close="
                () => {
                  //slotProps.unfocusOnNoChanges() || slotProps.focusOnSave()
                }
              "
              :focusOnMount="true"
              :multiple="true"
              track-by="slug"
              label="name"
              placeholder="Select"
              class="box-width min-width-full border"
              :query="TAGS_QUERY"
              :query-variables="{
                filters: {
                  type: { value: ['trafficType'] }
                },
                first: 100
              }"
            ></Superselect>
          </template>
        </EditableValue>
      </div>
    </div>
    <div class="x-row">
      <div class="x-col1">Actions</div>
      <div class="x-col2">
        <ClickAndReplace :duration="2000">
          <template v-slot>
            <YButton
              class="bg-gray-100 hover:bg-gray-200 text-gray-700"
              :is-loading="isDeleting"
              >Delete Campaign</YButton
            >
          </template>
          <template v-slot:replacement>
            <YButton
              class="bg-red-600 hover:bg-red-700 text-white"
              @click="deleteCampaign"
              :is-loading="isDeleting"
              >Confirm Delete</YButton
            >
          </template>
        </ClickAndReplace>
      </div>
    </div>
  </div>
</template>

<script>
import CAMPAIGN_QUERY from '@/graphql/Campaign/CampaignQuery.gql'
import UPDATE_CAMPAIGN_MUTATION from '@/graphql/Campaign/UpdateCampaignMutation.gql'
import SYNC_TAGS_WITH_TYPE_MUTATION from '@/graphql/Tag/SyncTagsWithTypeMutation.gql'
import TAGS_QUERY from '@/graphql/Tag/TagsQuery.gql'
import DELETE_CAMPAIGN_MUTATION from '@/graphql/Campaign/DeleteCampaignMutation.gql'
import EditModelPublisherPermissions from '@/views/Admin/Permissions/EditModelPublisherPermissions'
import SubscribeToCampaignWidget from '@/views/Admin/Campaign/SubscribeToCampaignWidget'
import ClickAndReplace from '@/components/ui/ClickAndReplace'
import IMAGES_QUERY from '@/graphql/ImageRepository/ImagesQuery.gql'
import EditableImage from '@/components/utilities/EditableImage'

export default {
  components: {
    ClickAndReplace,
    EditableImage
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  apollo: {
    campaign: {
      query: CAMPAIGN_QUERY,
      variables() {
        return {
          id: this.id
        }
      }
    }
  },
  data() {
    return {
      UPDATE_CAMPAIGN_MUTATION,
      SYNC_TAGS_WITH_TYPE_MUTATION,
      IMAGES_QUERY,
      isDeleting: false,
      isUploading: false,
      TAGS_QUERY,
      campaign: null,
      imageFile: null
    }
  },
  created() {
    if (this.campaign && this.imageFileComputed?.length > 0) {
      this.imageFile = this.imageFileComputed[0]
    }
  },
  computed: {
    displayImageFromRepository() {
      return this.campaign.images.length > 0 || !this.campaign.legacyImage
    },
    imageHasUnsavedChanges() {
      const campaignId = this.campaign?.images?.[0]?.id ?? null
      const imageFileId = this.imageFile?.id ?? null

      return campaignId !== imageFileId
    },
    advertisers() {
      if (this.campaign) {
        return this.campaign.advertisers
      } else {
        return null
      }
    },
    imageFileComputed() {
      if (this.campaign.images) {
        return this.campaign.images
          .filter(image => image.type === 'template')
          .map(image => {
            return {
              id: image.id,
              name: image.name,
              imageThumbnailUrl: image.conversions.find(
                image => image.conversion === 'thumb'
              ).storageUrl
            }
          })
      }
      return null
    }
  },
  methods: {
    cancelImage() {
      if (this.campaign && this.imageFileComputed?.length > 0) {
        this.imageFile = this.imageFileComputed[0]
      }
      return (this.imageFile = null)
    },
    deleteCampaign() {
      this.isDeleting = true
      this.$apollo
        .mutate({
          mutation: DELETE_CAMPAIGN_MUTATION,
          variables: {
            id: this.campaign.id
          }
        })
        .then(() => {
          this.isDeleting = false
          this.$toastr.s('Campaign Deleted')
          this.$router.push({ name: 'Campaigns' })
        })
        .catch(error => {
          this.isDeleting = false
          console.error(error)
        })
    },
    saveImage() {
      this.isUploading = true
      if (this.imageHasUnsavedChanges) {
        this.$apollo
          .mutate({
            mutation: UPDATE_CAMPAIGN_MUTATION,
            variables: {
              input: {
                id: this.campaign.id,
                images: [{ id: this.imageFile.id, type: 'template' }]
              }
            }
          })
          .then(() => {
            this.isUploading = false
            this.$toastr.s('Image Saved')
          })
          .catch(error => {
            this.isUploading = false
            console.error(error)
          })
      }
    },
    openSubscribeUserModal() {
      this.$modal.show(
        SubscribeToCampaignWidget,
        {
          campaignId: this.id
        },
        {
          classes: ['overflow-visible-important'],
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    },
    openPermissionsModal() {
      this.$modal.show(
        EditModelPublisherPermissions,
        {
          modelIds: [this.id],
          modelClass: 'campaign',
          ability: 'query',
          all: this.campaign.publisherAbilitySummary.all,
          only: this.campaign.publisherAbilitySummary.only,
          except: this.campaign.publisherAbilitySummary.except,
          onSuccess: () => {}
        },
        {
          classes: ['overflow-visible-important'],
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    }
  }
}
</script>

<style scoped>
.x-row {
  @apply flex items-center py-4;
}
.x-col1 {
  @apply w-48 text-right px-4 font-bold text-gray-700;
  min-width: 12rem;
}
.x-col2 {
  @apply flex-grow;
}
.box-width {
  max-width: 20rem;
}
</style>
