var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Tabs', {
    attrs: {
      "show-type": "show"
    }
  }, [_c('Tab', {
    attrs: {
      "name": this.tab1,
      "active": true
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function () {
        return [_vm.algorithm1Weight !== null ? _c('Tag', {
          attrs: {
            "color": "purple"
          }
        }, [_vm.algorithm1Weight !== null ? _c('EditableValue', {
          attrs: {
            "type": "contentEditable",
            "mutation": _vm.EDIT_ALGORITHM_USAGE_WEIGHT,
            "variables": {
              input: {
                id: 2,
                weight: '{value}'
              }
            },
            "value": _vm.algorithm1Weight,
            "transformValueForQuery": function (value) {
              return parseInt(value);
            }
          }
        }) : _vm._e(), _vm._v("% ")], 1) : _vm._e()];
      },
      proxy: true
    }])
  }, [_c('AlgorithmTwo')], 1), _c('Tab', {
    attrs: {
      "name": this.tab9
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function () {
        return [_vm.algorithm9Weight !== null ? _c('Tag', {
          attrs: {
            "color": "purple"
          }
        }, [_vm.algorithm9Weight !== null ? _c('EditableValue', {
          attrs: {
            "type": "contentEditable",
            "mutation": _vm.EDIT_ALGORITHM_USAGE_WEIGHT,
            "variables": {
              input: {
                id: 9,
                weight: '{value}'
              }
            },
            "value": _vm.algorithm9Weight,
            "transformValueForQuery": function (value) {
              return parseInt(value);
            }
          }
        }) : _vm._e(), _vm._v(" %")], 1) : _vm._e()];
      },
      proxy: true
    }])
  }, [_c('AlgorithmNine')], 1), _c('Tab', {
    attrs: {
      "name": this.tab10
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function () {
        return [_vm.algorithm10Weight !== null ? _c('Tag', {
          attrs: {
            "color": "purple"
          }
        }, [_vm.algorithm10Weight !== null ? _c('EditableValue', {
          attrs: {
            "type": "contentEditable",
            "mutation": _vm.EDIT_ALGORITHM_USAGE_WEIGHT,
            "variables": {
              input: {
                id: 10,
                weight: '{value}'
              }
            },
            "value": _vm.algorithm10Weight,
            "transformValueForQuery": function (value) {
              return parseInt(value);
            }
          }
        }) : _vm._e(), _vm._v(" %")], 1) : _vm._e()];
      },
      proxy: true
    }])
  }, [_c('AlgorithmTen')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }