var render = function render(){
  var _vm$wiseAccountDetail;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "grid grid-4 gap-2 py-2"
  }, [(_vm$wiseAccountDetail = _vm.wiseAccountDetails) !== null && _vm$wiseAccountDetail !== void 0 && _vm$wiseAccountDetail.accountRequirementsData && Object.keys(this.filters).length != 0 ? _c('div', {
    staticClass: "grid grid-4 gap-2 py-2"
  }, [_c('YInput', {
    attrs: {
      "label": "Account Holder Full Name"
    },
    model: {
      value: _vm.filters.name.fullName,
      callback: function ($$v) {
        _vm.$set(_vm.filters.name, "fullName", $$v);
      },
      expression: "filters.name.fullName"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Recieve Currency",
      "track-by": "code",
      "label": "code",
      "multiple": false,
      "options": _vm.getCurrencies || [],
      "loading": _vm.$apollo.queries.wiseCurrencies.loading
    },
    on: {
      "select": _vm.refetchAccountRequirements
    },
    model: {
      value: _vm.currency,
      callback: function ($$v) {
        _vm.currency = $$v;
      },
      expression: "currency"
    }
  }), _vm.currency ? _c('div', [_c('Superselect', {
    attrs: {
      "title": "Pay Option",
      "track-by": "key",
      "label": "title",
      "multiple": false,
      "options": this.payOptions || [],
      "loading": _vm.$apollo.queries.wiseAccountDetails.loading
    },
    on: {
      "select": _vm.resetADetails
    },
    model: {
      value: _vm.type,
      callback: function ($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  })], 1) : _vm._e(), _vm.type ? _c('div', [_vm.type.key == 'Email' || _vm.type.key == 'email' ? _c('div', [_c('YInput', {
    attrs: {
      "label": "Prefered Email"
    },
    model: {
      value: _vm.filters.aDetails['email'],
      callback: function ($$v) {
        _vm.$set(_vm.filters.aDetails, 'email', $$v);
      },
      expression: "filters.aDetails['email']"
    }
  })], 1) : _vm._e(), _vm._l(_vm.getAccountRequirements.requirementsData, function (value, key) {
    return _c('div', {
      key: key
    }, [value.fieldType == 'input' || value.fieldType == 'text' ? _c('div', [_c('YInput', {
      attrs: {
        "label": value === null || value === void 0 ? void 0 : value.name
      },
      model: {
        value: _vm.filters.aDetails[value === null || value === void 0 ? void 0 : value.key],
        callback: function ($$v) {
          _vm.$set(_vm.filters.aDetails, value === null || value === void 0 ? void 0 : value.key, $$v);
        },
        expression: "filters.aDetails[value?.key]"
      }
    })], 1) : _vm._e(), value.fieldType == 'select' ? _c('div', [_c('Superselect', {
      attrs: {
        "title": value === null || value === void 0 ? void 0 : value.name,
        "track-by": "key",
        "label": "name",
        "multiple": false,
        "options": (value === null || value === void 0 ? void 0 : value.valuesAllowed) || [],
        "loading": _vm.$apollo.queries.wiseAccountDetails.loading
      },
      model: {
        value: _vm.filters.aDetails[value === null || value === void 0 ? void 0 : value.key],
        callback: function ($$v) {
          _vm.$set(_vm.filters.aDetails, value === null || value === void 0 ? void 0 : value.key, $$v);
        },
        expression: "filters.aDetails[value?.key]"
      }
    })], 1) : _vm._e()]);
  })], 2) : _vm._e()], 1) : _vm._e(), _c('div', [_c('YButton', {
    staticClass: "btn btn-blue",
    attrs: {
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": _vm.saveWiseDetails,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.saveWiseDetails.apply(null, arguments);
      }
    }
  }, [_vm._v("Save")])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }