<template>
  <div :key="reportId ?? 'default'">
    <ReportFilters
      ref="form"
      v-show="showFilters"
      :link="this"
      :master-column-data="masterColumnData"
      :create-vuetable-columns="createVuetableColumns"
      :persist-state="persistState"
      :auto-load="autoLoad"
      :default-date-range="defaultDateRange"
      :default-filters="defaultFilters"
      :enablePresets="enablePresets"
      :initial-preset-id="initialPresetId"
      :reportId="reportId"
    >
    </ReportFilters>
    <Widget
      :class="[hasEverLoaded ? '' : 'disabled', 'mt-2']"
      :loading="isLoading"
    >
      <WidgetHeader>
        <template slot="rawContent">
          <slot name="table-header">
            <h4 class="widget-title">{{ readableDateRange }}</h4>
          </slot>
          <slot name="right">
            <Spinner v-if="isLoading" :size="35" :color="`green`" />
            <div class="flex" v-else>
              <YButton
                type="button"
                class="mr-1 border border-blue-600 hover:bg-blue-600 focus:bg-blue-600 text-blue-600 hover:text-white focus:text-white mr-3 outline-none-important"
                @click="exportData"
                :disabled="isExporting || isLoading"
                :is-loading="isExporting"
                >Export</YButton
              >
              <v-popover
                offset="10"
                placement="left-start"
                :autoHide="true"
                popoverClass=""
                :class="['sidebar-user-popover']"
                popoverBaseClass="popover-menu sidebar-popover-menu"
                popoverInnerClass="popover-menu-inner animated animated-speed1 fadeIn"
              >
                <!-- This will be the popover target (for the events and position) -->
                <YButton class="btn btn-transparent btn-rounded tooltip-target"
                  ><Icon name="dotsVertical" :size="4"
                /></YButton>

                <!-- This will be the content of the popover -->
                <template slot="popover">
                  <ul class="list-group popover-menu-listgroup">
                    <li class="list-group-item">
                      <a
                        class="list-group-link"
                        @click="$modal.show('report-load-times')"
                        ><i class="zmdi zmdi-time mr-2"></i> View Load Times</a
                      >
                    </li>
                  </ul>
                </template>
              </v-popover>
            </div>
          </slot>
        </template>
      </WidgetHeader>
      <WidgetBody
        :class="[
          $refs.form && $refs.form.isSubmitting ? 'reports-loading' : ''
        ]"
      >
        <slot name="table-body-start"></slot>
        <div class="row">
          <div class="col overflow-x-scroll">
            <vuetable
              ref="vuetable"
              class="reports-table table-striped table-hover"
              :api-mode="false"
              :fields="fields"
              :data="vuetableData"
              :data-total="dataTotal"
              pagination-path="pagination"
              data-path="data"
              :css="css"
              :sort-order="sortOrder"
              :multi-sort="multiSort"
              :per-page="perPage"
              multi-sort-key="shift"
              cellspacing="0"
              width="100%"
              table-wrapper="vuetable-wrapper"
              @vuetable:loading="onLoading"
              @vuetable:loaded="onLoaded"
            >
              <template slot="tableTotals" slot-scope="props">
                <tr class="spacer"></tr>
                <tr>
                  <td v-for="field in props.fields" :key="field.name">
                    <span v-text="formatTotalField(field)"></span>
                  </td>
                </tr>
                <tr class="spacer h-3"></tr>
              </template>
              <div slot="publisher-slot" slot-scope="props">
                <template v-if="props.rowData.publisher">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.publisher"
                      v-model="$refs.form.filters.publisher.value"
                      :controller-value="{
                        id: props.rowData.publisher.split(' - ')[0],
                        label: props.rowData.publisher
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <router-link
                      :to="{
                        name: 'User',
                        params: { id: props.rowData.publisher.split(' - ')[0] }
                      }"
                      class="link truncate block"
                      style="max-width: 13rem;"
                    >
                      {{ props.rowData.publisher }}
                    </router-link>
                  </div>
                </template>
              </div>
              <div slot="conversion-type-slot" slot-scope="props">
                <template v-if="props.rowData.conversionType">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.conversionType"
                      v-model="$refs.form.filters.conversionType.value"
                      :controller-value="{
                        id: props.rowData.conversionType,
                        label: props.rowData.conversionType
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.conversionType"></span>
                  </div>
                </template>
              </div>
              <div slot="payout-conversion-type-slot" slot-scope="props">
                <template v-if="props.rowData.payoutConversionType">
                  <div class="report-value">
                    <span v-text="props.rowData.payoutConversionType"></span>
                  </div>
                </template>
              </div>
              <div slot="postback-conversion-type-slot" slot-scope="props">
                <template v-if="props.rowData.postbackConversionType">
                  <div class="report-value">
                    <span v-text="props.rowData.postbackConversionType"></span>
                  </div>
                </template>
              </div>
              <div slot="publisher-status-slot" slot-scope="props">
                <template v-if="props.rowData.publisherStatus">
                  <Tag :key="props.rowData.publisherStatus">{{
                    props.rowData.publisherStatus
                  }}</Tag>
                </template>
              </div>
              <div slot="advertiser-slot" slot-scope="props">
                <template v-if="props.rowData.advertiser">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.advertiser"
                      v-model="$refs.form.filters.advertiser.value"
                      :controller-value="{
                        id: props.rowData.advertiser.split(' - ')[0],
                        label: props.rowData.advertiser
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <router-link
                      :to="{
                        name: 'Advertiser',
                        params: {
                          id: props.rowData.advertiser.split(' - ')[0],
                          tab: 'details'
                        }
                      }"
                      class="link truncate block"
                      style="max-width: 13rem;"
                    >
                      {{ props.rowData.advertiser }}
                    </router-link>
                  </div>
                </template>
              </div>
              <div slot="country-slot" slot-scope="props">
                <div class="report-value">
                  <ReportFilterValueController
                    v-if="props.rowData.country"
                    v-model="$refs.form.filters.country.value"
                    :controller-value="{
                      id: props.rowData.country,
                      label: props.rowData.country
                    }"
                    class="pr-2"
                  ></ReportFilterValueController>
                  <span v-text="props.rowData.country"></span>
                </div>
              </div>
              <div slot="campaign-slot" slot-scope="props">
                <template v-if="props.rowData.campaign">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.campaign"
                      v-model="$refs.form.filters.campaign.value"
                      :controller-value="{
                        id: props.rowData.campaign.split(' - ')[0],
                        label: props.rowData.campaign
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <router-link
                      :to="{
                        name: 'Campaign',
                        params: {
                          id: props.rowData.campaign.split(' - ')[0]
                        }
                      }"
                      class="link without-truncate block"
                      style="max-width: 13rem;"
                    >
                      {{ props.rowData.campaign }}
                    </router-link>
                  </div>
                </template>
              </div>
              <div slot="redirect-slot" slot-scope="props">
                <template v-if="props.rowData.redirect">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.redirect"
                      v-model="$refs.form.filters.redirect.value"
                      :controller-value="{
                        id: props.rowData.redirect.split(' - ')[0],
                        label: props.rowData.redirect
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <i
                      v-if="
                        props.rowData.hasOwnProperty('redirect') &&
                          props.rowData.hasOwnProperty('redirectLink') &&
                          props.rowData.redirect.split(' - ')[1] !=
                            props.rowData.redirectLink
                      "
                      class="zmdi zmdi-alert-triangle text-danger pr-2"
                      v-tooltip="`Domain Mismatch`"
                    ></i>
                    <router-link
                      :to="{
                        name: 'Redirect',
                        params: {
                          id: props.rowData.redirect.split(' - ')[0]
                        }
                      }"
                      class="link truncate block"
                      style="max-width: 13rem;"
                    >
                      {{ props.rowData.redirect }}
                    </router-link>
                  </div>
                </template>
              </div>
              <div slot="redirect-link-slot" slot-scope="props">
                <template v-if="props.rowData.redirectLink">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.redirectLink"
                      v-model="$refs.form.filters.redirectLink.value"
                      :controller-value="{
                        id: props.rowData.redirectLink,
                        label: props.rowData.redirectLink
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <i
                      v-if="
                        props.rowData.hasOwnProperty('redirect') &&
                          props.rowData.hasOwnProperty('redirectLink') &&
                          props.rowData.redirect.split(' - ')[1] !=
                            props.rowData.redirectLink
                      "
                      class="zmdi zmdi-alert-triangle text-danger pr-2"
                      v-tooltip="`Domain Mismatch`"
                    ></i>
                    <span v-text="props.rowData.redirectLink"></span>
                  </div>
                </template>
              </div>
              <div slot="mini-domain-slot" slot-scope="props">
                <template v-if="props.rowData.miniDomain">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.miniDomain"
                      v-model="$refs.form.filters.miniDomain.value"
                      :controller-value="{
                        id: props.rowData.miniDomain,
                        label: props.rowData.miniDomain
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.miniDomain"></span>
                  </div>
                </template>
              </div>
              <div slot="offer-slot" slot-scope="props">
                <template v-if="props.rowData.offer">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.offer"
                      v-model="$refs.form.filters.offer.value"
                      :controller-value="{
                        id: props.rowData.offer.split(' - ')[0],
                        label: props.rowData.offer
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.offer"></span>
                  </div>
                </template>
              </div>
              <div slot="source-slot" slot-scope="props">
                <template v-if="props.rowData.source">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.source"
                      v-model="$refs.form.filters.source.value"
                      :controller-value="{
                        id: props.rowData.source.split(' - ')[0],
                        label: props.rowData.source
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <router-link
                      :to="{
                        name: 'Source',
                        params: {
                          id: props.rowData.source.split(' - ')[0]
                        }
                      }"
                      class="link truncate block"
                      style="max-width: 13rem;"
                    >
                      {{ props.rowData.source }}
                    </router-link>
                  </div>
                </template>
              </div>
              <div slot="template-slot" slot-scope="props">
                <template v-if="props.rowData.template">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.template"
                      v-model="$refs.form.filters.template.value"
                      :controller-value="{
                        id: props.rowData.template.split(' - ')[0],
                        label: props.rowData.template
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.template"></span>
                  </div>
                </template>
              </div>
              <div slot="group-service-slot" slot-scope="props">
                <template v-if="props.rowData.groupService">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.groupService"
                      v-model="$refs.form.filters.groupService.value"
                      :controller-value="{
                        id: props.rowData.groupService.split(' - ')[0],
                        label: props.rowData.groupService
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    {{ props.rowData.groupService }}
                  </div>
                </template>
              </div>
              <div slot="mini-slot" slot-scope="props">
                <template v-if="props.rowData.mini">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.mini"
                      v-model="$refs.form.filters.mini.value"
                      :controller-value="{
                        id: props.rowData.mini,
                        label: props.rowData.mini
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.mini"></span>
                  </div>
                </template>
              </div>
              <div slot="vertical-slot" slot-scope="props">
                <template v-if="props.rowData.vertical">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.vertical"
                      v-model="$refs.form.filters.vertical.value"
                      :controller-value="{
                        id: props.rowData.vertical.split(' - ')[0],
                        label: props.rowData.vertical
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.vertical"></span>
                  </div>
                </template>
              </div>
              <div slot="mobile-desktop-slot" slot-scope="props">
                <template v-if="props.rowData.mobileDesktop">
                  <div class="report-value">
                    <a
                      href="#"
                      class="mr-2"
                      :class="[
                        (props.rowData.mobileDesktop == 'Mobile' &&
                          $refs.form.filters.mobileDesktop.modifiers.exclude !=
                            false) ||
                        (props.rowData.mobileDesktop == 'Desktop' &&
                          $refs.form.filters.mobileDesktop.modifiers.exclude ==
                            false)
                          ? 'text-muted'
                          : ''
                      ]"
                      @click.prevent="
                        props.rowData.mobileDesktop == 'Mobile'
                          ? ($refs.form.filters.mobileDesktop.modifiers.exclude = false)
                          : ($refs.form.filters.mobileDesktop.modifiers.exclude = true)
                      "
                    >
                      <Icon name="target" :size="4" />
                    </a>

                    <span v-text="props.rowData.mobileDesktop"></span>
                  </div>
                </template>
              </div>

              <div slot="is-yleader-slot" slot-scope="props">
                <template v-if="props.rowData.isYleader">
                  <div class="report-value">
                    <a
                      href="#"
                      class="mr-2"
                      :class="[
                        props.rowData.isYleader &&
                        $refs.form.filters.isYleader.modifiers.exclude != false
                          ? 'text-muted'
                          : ''
                      ]"
                    >
                      <Icon name="target" :size="4" />
                    </a>
                    <span v-text="props.rowData.isYleader"></span>
                  </div>
                </template>
              </div>
              <div slot="campaign-smart-link-slot" slot-scope="props">
                <template v-if="props.rowData.campaignSmartLink">
                  <div class="report-value">
                    <a
                      href="#"
                      class="mr-2"
                      :class="[
                        props.rowData.campaignSmartLink &&
                        $refs.form.filters.campaignSmartLink.modifiers
                          .exclude != false
                          ? 'text-muted'
                          : ''
                      ]"
                    >
                      <Icon name="target" :size="4" />
                    </a>
                    <span v-text="props.rowData.campaignSmartLink"></span>
                  </div>
                </template>
              </div>
              <div slot="region-code-slot" slot-scope="props">
                <template v-if="props.rowData.regionCode">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.regionCode"
                      v-model="$refs.form.filters.regionCode.value"
                      :controller-value="{
                        id: props.rowData.regionCode,
                        label: props.rowData.regionCode
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.regionCode"></span>
                  </div>
                </template>
              </div>

              <div slot="browser-family-slot" slot-scope="props">
                <template v-if="props.rowData.browserFamily">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.browserFamily"
                      v-model="$refs.form.filters.browserFamily.value"
                      :controller-value="{
                        id: props.rowData.browserFamily,
                        label: props.rowData.browserFamily
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.browserFamily"></span>
                  </div>
                </template>
              </div>

              <div slot="browser-slot" slot-scope="props">
                <template v-if="props.rowData.browser">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.browser"
                      v-model="$refs.form.filters.browser.value"
                      :controller-value="{
                        id: props.rowData.browser,
                        label: props.rowData.browser
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.browser"></span>
                  </div>
                </template>
              </div>
              <div slot="algorithm-id-slot" slot-scope="props">
                <template v-if="props.rowData.algorithmId">
                  <div class="report-value">
                    <span v-text="props.rowData.algorithmId">{{
                      props.rowData.algorithmId
                    }}</span>
                  </div>
                </template>
              </div>
              <div slot="os-slot" slot-scope="props">
                <template v-if="props.rowData.os">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.os"
                      v-model="$refs.form.filters.os.value"
                      :controller-value="{
                        id: props.rowData.os,
                        label: props.rowData.os
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.os"></span>
                  </div>
                </template>
              </div>
              <div slot="device-slot" slot-scope="props">
                <template v-if="props.rowData.device">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.device"
                      v-model="$refs.form.filters.device.value"
                      :controller-value="{
                        id: props.rowData.device,
                        label: props.rowData.device
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.device"></span>
                  </div>
                </template>
              </div>
              <div slot="ip-slot" slot-scope="props">
                <template v-if="props.rowData.ip">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.ip"
                      v-model="$refs.form.filters.ip.value"
                      :controller-value="{
                        id: props.rowData.ip,
                        label: props.rowData.ip
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.ip"></span>
                  </div>
                </template>
              </div>
              <div slot="forwarded-for-ip-slot" slot-scope="props">
                <template v-if="props.rowData.forwardedForIp">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.forwardedForIp"
                      v-model="$refs.form.filters.forwardedForIp.value"
                      :controller-value="{
                        id: props.rowData.forwardedForIp,
                        label: props.rowData.forwardedForIp
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.forwardedForIp"></span>
                  </div>
                </template>
              </div>
              <div slot="usage-type-slot" slot-scope="props">
                <template v-if="props.rowData.usageType">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.usageType"
                      v-model="$refs.form.filters.usageType.value"
                      :controller-value="{
                        id: props.rowData.usageType,
                        label: props.rowData.usageType
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.usageType"></span>
                  </div>
                </template>
              </div>
              <div slot="connection-type-slot" slot-scope="props">
                <template v-if="props.rowData.connectionType">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.connectionType"
                      v-model="$refs.form.filters.connectionType.value"
                      :controller-value="{
                        id: props.rowData.connectionType,
                        label: props.rowData.connectionType
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.connectionType"></span>
                  </div>
                </template>
              </div>
              <div slot="isp-slot" slot-scope="props">
                <template v-if="props.rowData.isp">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.isp"
                      v-model="$refs.form.filters.isp.value"
                      :controller-value="{
                        id: props.rowData.isp,
                        label: props.rowData.isp
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.isp"></span>
                  </div>
                </template>
              </div>
              <div slot="language-slot" slot-scope="props">
                <template v-if="props.rowData.language">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.language"
                      v-model="$refs.form.filters.language.value"
                      :controller-value="{
                        id: props.rowData.language,
                        label: props.rowData.language
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.language"></span>
                  </div>
                </template>
              </div>
              <div slot="keyword-slot" slot-scope="props">
                <template v-if="props.rowData.keyword">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.keyword"
                      v-model="$refs.form.filters.keyword.value"
                      :controller-value="{
                        id: props.rowData.keyword,
                        label: props.rowData.keyword
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.keyword"></span>
                  </div>
                </template>
              </div>
              <div slot="subid-slot" slot-scope="props">
                <template v-if="props.rowData.subId">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.subId"
                      v-model="$refs.form.filters.subId.value"
                      :controller-value="{
                        id: props.rowData.subId,
                        label: props.rowData.subId
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.subId"></span>
                  </div>
                </template>
              </div>
              <div slot="referer-slot" slot-scope="props">
                <template v-if="props.rowData.referer">
                  <div class="report-value">
                    <!-- 		    					<ReportFilterValueController 
		    						v-if="props.rowData.referer"
		    						v-model="$refs.form.filters.referer.value" 
		    						:controller-value="{id: props.rowData.referer, label: props.rowData.referer}" 
		    						class="pr-2"
		    					></ReportFilterValueController> -->
                    <span v-text="props.rowData.referer"></span>
                  </div>
                </template>
              </div>
              <div slot="impression-id-slot" slot-scope="props">
                <template v-if="props.rowData.impressionId">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.impressionId"
                      v-model="$refs.form.filters.impressionId.value"
                      :controller-value="{
                        id: props.rowData.impressionId,
                        label: props.rowData.impressionId
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.impressionId"></span>
                  </div>
                </template>
              </div>
              <div slot="click-id-slot" slot-scope="props">
                <template v-if="props.rowData.clickId">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.clickId"
                      v-model="$refs.form.filters.clickId.value"
                      :controller-value="{
                        id: props.rowData.clickId,
                        label: props.rowData.clickId
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.clickId"></span>
                  </div>
                </template>
              </div>
              <div slot="advertiser-account-manager-slot" slot-scope="props">
                <template v-if="props.rowData.advertiserAccountManager">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.advertiserAccountManager"
                      v-model="
                        $refs.form.filters.advertiserAccountManager.value
                      "
                      :controller-value="{
                        id: props.rowData.advertiserAccountManager.split(
                          ' - '
                        )[0],
                        label: props.rowData.advertiserAccountManager
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    {{ props.rowData.advertiserAccountManager }}
                  </div>
                </template>
              </div>
              <div slot="publisher-account-manager-slot" slot-scope="props">
                <template v-if="props.rowData.publisherAccountManager">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.publisherAccountManager"
                      v-model="$refs.form.filters.publisherAccountManager.value"
                      :controller-value="{
                        id: props.rowData.publisherAccountManager.split(
                          ' - '
                        )[0],
                        label: props.rowData.publisherAccountManager
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    {{ props.rowData.publisherAccountManager }}
                  </div>
                </template>
              </div>
              <div slot="advertiser-tracking-platform-slot" slot-scope="props">
                <template v-if="props.rowData.advertiserTrackingPlatform">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.advertiserTrackingPlatform"
                      v-model="
                        $refs.form.filters.advertiserTrackingPlatform.value
                      "
                      :controller-value="{
                        id: props.rowData.advertiserTrackingPlatform,
                        label: props.rowData.advertiserTrackingPlatform
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    {{ props.rowData.advertiserTrackingPlatform }}
                  </div>
                </template>
              </div>
              <div slot="publisher-tracking-platform-slot" slot-scope="props">
                <template v-if="props.rowData.publisherTrackingPlatform">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.publisherTrackingPlatform"
                      v-model="
                        $refs.form.filters.publisherTrackingPlatform.value
                      "
                      :controller-value="{
                        id: props.rowData.publisherTrackingPlatform,
                        label: props.rowData.publisherTrackingPlatform
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    {{ props.rowData.publisherTrackingPlatform }}
                  </div>
                </template>
              </div>
            </vuetable>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col col-md-5">
            <ytrack-vuetable-pagination-info
              ref="paginationInfo"
              small="true"
            ></ytrack-vuetable-pagination-info>
          </div>
          <div class="col col-md-7">
            <ytrack-vuetable-pagination
              ref="pagination"
              @vuetable-pagination:change-page="onChangePage"
              small="true"
              class="mt-0"
            ></ytrack-vuetable-pagination>
          </div>
        </div> -->
      </WidgetBody>
    </Widget>
    <modal
      name="report-load-times"
      width="95%"
      height="auto"
      :max-width="600"
      :adaptive="true"
      v-show="loadTimes"
    >
      <y-time-log
        :times="JSON.parse(loadTimes)"
        title="Report Load Times"
      ></y-time-log>
    </modal>
  </div>
</template>

<script>
import Vuetable from '@/components/legacy/vuetable-2-2-custom/src/components/Vuetable'
import ReportFilters from './ReportFilters'
import YTimeLog from '@/components/legacy/yTimeLog'
import ReportFilterValueController from '@/views/Admin/Reports/ReportFilterValueController'
import { objectToCsv, saveCsvString } from '@/assets/js/CsvTools'

export default {
  components: {
    Vuetable,
    ReportFilters,
    YTimeLog,
    ReportFilterValueController
  },
  props: {
    showFilters: {
      default: false,
      type: Boolean
    },
    persistState: {
      default: false,
      type: Boolean
    },
    autoLoad: {
      default: false,
      type: Boolean
    },
    defaultDateRange: {
      type: Object
    },
    defaultFilters: {
      type: Object
    },
    initialPresetId: {
      type: String,
      default: null
    },
    reportId: {
      type: String,
      default: null
    },
    enablePresets: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let masterColumnData = {
      day: {
        filter: {
          id: 'show-day',
          label: 'Day',
          state: true,
          visible: true,
          disabled: false,
          group: 'date'
        },
        column: {
          name: 'day',
          title: 'Date',
          sortField: 'day',
          dataClass: '',
          titleClass: '',
          visible: true,
          defSortDirection: 'desc'
        }
      },
      month: {
        filter: {
          id: 'show-month',
          label: 'Month',
          state: false,
          visible: true,
          disabled: false,
          group: 'date'
        },
        column: {
          name: 'month',
          title: 'Date',
          sortField: 'month',
          dataClass: '',
          titleClass: '',
          visible: true,
          defSortDirection: 'desc'
        }
      },
      hour: {
        filter: {
          id: 'show-hour',
          label: 'Hour',
          state: false,
          visible: true,
          disabled: false,
          group: 'date'
        },
        column: {
          name: 'hour',
          title: 'Date',
          sortField: 'hour',
          dataClass: '',
          titleClass: '',
          visible: true,
          defSortDirection: 'desc'
        }
      },
      impressionId: {
        filter: {
          id: 'show-impression-id',
          label: 'Impression ID',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'impression-id-slot',
          title: 'Impression ID',
          sortField: 'impressionId',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      clickId: {
        filter: {
          id: 'show-click-id',
          label: 'Click ID',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'click-id-slot',
          title: 'Click ID',
          sortField: 'clickId',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      conversionType: {
        filter: {
          id: 'show-conversion-type',
          label: 'Conversion Type',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'conversion-type-slot',
          title: 'Conversion Type',
          sortField: 'conversionType',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      payoutConversionType: {
        filter: {
          id: 'show-payout-conversion-type',
          label: 'Payout Conversion Type',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'payout-conversion-type-slot',
          title: 'Payout Conversion Type',
          sortField: 'payoutConversionType',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      postbackConversionType: {
        filter: {
          id: 'show-postback-conversion-type',
          label: 'Postback Conversion Type',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'postback-conversion-type-slot',
          title: 'Postback Conversion Type',
          sortField: 'postbackConversionType',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      country: {
        filter: {
          id: 'show-country',
          label: 'Country',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'country-slot',
          title: 'Country',
          sortField: 'country',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      algorithmId: {
        filter: {
          id: 'show-algorithm-id',
          label: 'Algorithm ID',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'algorithm-id-slot',
          title: 'Algorithm ID',
          sortField: 'algorithmId',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      publisher: {
        filter: {
          id: 'show-publisher',
          label: 'Publisher',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'publisher-slot',
          title: 'Publisher',
          sortField: 'publisher',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      publisherId: {
        filter: {
          id: 'show-publisher-id',
          label: 'Publisher ID',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'publisherId',
          title: 'Publisher ID',
          sortField: 'publisherId',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      publisherLogin: {
        filter: {
          id: 'show-publisher-id',
          label: 'Publisher Login',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'publisherLogin',
          title: 'Publisher Login',
          sortField: 'publisherLogin',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      publisherStatus: {
        filter: {
          id: 'show-publisher-status',
          label: 'Publisher Status',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'publisher-status-slot',
          title: 'Publisher Status',
          sortField: 'publisherStatus',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      publisherAccountManager: {
        filter: {
          id: 'show-publisher-account-manager',
          label: 'Publisher Account Manager',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'publisher-account-manager-slot',
          title: 'Publisher Account Manager',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      publisherTrackingPlatform: {
        filter: {
          id: 'show-publisher-tracking-platform',
          label: 'Publisher Tracking Platform',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'publisher-tracking-platform-slot',
          title: 'Publisher Tracking Platform',
          sortField: 'publisherTrackingPlatform',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      publisherPaymentTerms: {
        filter: {
          id: 'show-publisher-payment-terms',
          label: 'Publisher Payment Terms',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'publisherPaymentTerms',
          title: 'Publisher Payment Terms',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      publisherPaymentFrequency: {
        filter: {
          id: 'show-publisher-payment-frequency',
          label: 'Publisher Payment Frequency',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'publisherPaymentFrequency',
          title: 'Publisher Payment Frequency',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      publisherTax: {
        filter: {
          id: 'show-publisher-tax',
          label: 'Publisher Tax',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'publisherTax',
          title: 'Publisher Tax',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      advertiserPaymentTerms: {
        filter: {
          id: 'show-advertiser-payment-terms',
          label: 'Advertiser Payment Terms',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'advertiserPaymentTerms',
          title: 'Advertiser Payment Terms',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      advertiserPaymentFrequency: {
        filter: {
          id: 'show-advertiser-payment-frequency',
          label: 'Advertiser Payment Frequency',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'advertiserPaymentFrequency',
          title: 'Advertiser Payment Frequency',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      advertiserTax: {
        filter: {
          id: 'show-advertiser-tax',
          label: 'Advertiser Tax',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'advertiserTax',
          title: 'Advertiser Tax',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      tipaltiPublisherCountry: {
        filter: {
          id: 'show-tipalti-publisher-country',
          label: 'Tiplati Publisher Country',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'tipaltiPublisherCountry',
          title: 'Tiplati Country',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      tipaltiPublisherCity: {
        filter: {
          id: 'show-tipalit-publisher-city',
          label: 'Tipalti Publisher City',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'tipaltiPublisherCity',
          title: 'Tipalti City',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      tipaltiPublisherName: {
        filter: {
          id: 'show-tipalti-publisher-name',
          label: 'Tipalti Publisher Name',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'tipaltiPublisherName',
          title: 'Tipalti Name',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      tipaltiPublisherCompanyName: {
        filter: {
          id: 'show-tipalti-publisher-company-name',
          label: 'Tiptali Company Name',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'tipaltiPublisherCompanyName',
          title: 'Tipalti Company Name',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      tipaltiPublisherPaymentMethod: {
        filter: {
          id: 'show-tipalti-publisher-payment-method',
          label: 'Tipalti Payment Method',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'tipaltiPublisherPaymentMethod',
          title: 'Tipalti Payment Mehtod',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      campaign: {
        filter: {
          id: 'show-campaign',
          label: 'Campaign',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'campaign-slot',
          title: 'Campaign',
          sortField: 'campaign',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      advertiser: {
        filter: {
          id: 'show-advertiser',
          label: 'Advertiser',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'advertiser-slot',
          title: 'Advertiser',
          sortField: 'advertiser',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      advertiserId: {
        filter: {
          id: 'show-advertiser-id',
          label: 'Advertiser ID',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'advertiserId',
          title: 'Advertiser ID',
          sortField: 'advertiserId',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      advertiserName: {
        filter: {
          id: 'show-advertiser-name',
          label: 'Advertiser Name',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'advertiserName',
          title: 'Advertiser Name',
          sortField: 'advertiserName',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      advertiserBillingName: {
        filter: {
          id: 'show-advertiser-billing-name',
          label: 'Advertiser Billing Name',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'advertiserBillingName',
          title: 'Advertiser Billing Name',
          sortField: 'advertiserBillingName',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      advertiserAccount: {
        filter: {
          id: 'show-advertiser-account',
          label: 'Advertiser Account',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'advertiserAccount',
          title: 'Advertiser Account',
          sortField: 'advertiserAccount',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      advertiserAccountManager: {
        filter: {
          id: 'show-advertiser-account-manager',
          label: 'Advertiser Account Manager',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'advertiser-account-manager-slot',
          title: 'Advertiser Account Manager',
          sortField: 'advertiserAccountManager',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      advertiserTrackingPlatform: {
        filter: {
          id: 'show-advertiser-tracking-platform',
          label: 'Advertiser Tracking Platform',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'advertiser-tracking-platform-slot',
          title: 'Advertiser Tracking Platform',
          sortField: 'advertiserTrackingPlatform',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      offer: {
        filter: {
          id: 'show-offer',
          label: 'Offer',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'offer-slot',
          title: 'Offer',
          sortField: 'offer',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      source: {
        filter: {
          id: 'show-source',
          label: 'Source',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'source-slot',
          title: 'Source',
          sortField: 'source',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      template: {
        filter: {
          id: 'show-template',
          label: 'Template',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'template-slot',
          title: 'Template',
          sortField: 'template',
          callback: '',
          dataClass: 'template-column',
          titleClass: 'template-column',
          visible: true
        }
      },
      // groupService: {
      //   filter: {
      //     id: 'show-group-service',
      //     label: 'Group Service',
      //     state: false,
      //     visible: true,
      //     disabled: false,
      //     group: 'dimensions'
      //   },
      //   column: {
      //     name: 'group-service-slot',
      //     title: 'Group Service',
      //     sortField: 'groupService',
      //     callback: '',
      //     dataClass: '',
      //     titleClass: '',
      //     visible: true
      //   }
      // },
      // mini: {
      //   filter: {
      //     id: 'show-mini',
      //     label: 'Mini',
      //     state: false,
      //     visible: true,
      //     disabled: false,
      //     group: 'dimensions'
      //   },
      //   column: {
      //     name: 'mini-slot',
      //     title: 'Mini',
      //     sortField: 'mini',
      //     callback: '',
      //     dataClass: '',
      //     titleClass: '',
      //     visible: true
      //   }
      // },
      vertical: {
        filter: {
          id: 'show-vertical',
          label: 'Vertical',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'vertical-slot',
          title: 'Vertical',
          sortField: 'vertical',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      browser: {
        filter: {
          id: 'show-browser',
          label: 'Browser',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'browser-slot',
          title: 'Browser',
          sortField: 'browser',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      os: {
        filter: {
          id: 'show-os',
          label: 'OS',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'os-slot',
          title: 'OS',
          sortField: 'os',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      device: {
        filter: {
          id: 'show-device',
          label: 'Device',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'device-slot',
          title: 'Device',
          sortField: 'device',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      usageType: {
        filter: {
          id: 'show-usage-type',
          label: 'Usage Type',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'usage-type-slot',
          title: 'Usage Type',
          sortField: 'usageType',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      connectionType: {
        filter: {
          id: 'show-connection-type',
          label: 'Connection Type',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'connection-type-slot',
          title: 'Connection Type',
          sortField: 'connectionType',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      isp: {
        filter: {
          id: 'show-isp-type',
          label: 'ISP',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'isp-slot',
          title: 'ISP',
          sortField: 'isp',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      ip: {
        filter: {
          id: 'show-ip',
          label: 'IP',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'ip-slot',
          title: 'IP',
          sortField: 'ip',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      forwardedForIp: {
        filter: {
          id: 'show-forwarded-for-ip',
          label: 'Forwarded For IP',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'forwarded-for-ip-slot',
          title: 'Forwarded For IP',
          sortField: 'forwardedForIp',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      redirect: {
        filter: {
          id: 'show-redirect',
          label: 'Redirect',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'redirect-slot',
          title: 'Redirect',
          sortField: 'redirect',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      redirectLink: {
        filter: {
          id: 'show-redirect-link',
          label: 'Redirect Link',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'redirect-link-slot',
          title: 'Redirect Link',
          sortField: 'redirectLink',
          //callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      miniDomain: {
        filter: {
          id: 'show-mini-domain',
          label: 'Mini Domain',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'mini-domain-slot',
          title: 'Mini Domain',
          sortField: 'miniDomain',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      mobileDesktop: {
        filter: {
          id: 'show-mobile-desktop',
          label: 'Mobile / Desktop',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'mobile-desktop-slot',
          title: 'Mobile / Desktop',
          sortField: 'mobileDesktop',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },

      isYleader: {
        filter: {
          id: 'show-is-yleader',
          label: 'yLeader',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'is-yleader-slot',
          title: 'yLeader',
          sortField: 'isYleader',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      campaignSmartLink: {
        filter: {
          id: 'show-campgaign-smart-link',
          label: 'Smart Link',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'campaign-smart-link-slot',
          title: 'Smart Link',
          sortField: 'campaignSmartLink',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      regionCode: {
        filter: {
          id: 'show-region-code',
          label: 'Region Code',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'region-code-slot',
          title: 'Region Code',
          sortField: 'regionCode',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      browserFamily: {
        filter: {
          id: 'show-browser-family',
          label: 'Browser Family',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'browser-family-slot',
          title: 'Browser Family',
          sortField: 'browserFamily',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      language: {
        filter: {
          id: 'show-language',
          label: 'Language',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'language-slot',
          title: 'Language',
          sortField: 'language',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      referer: {
        filter: {
          id: 'show-referer',
          label: 'Referer',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'referer-slot',
          title: 'Referer',
          sortField: 'referer',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      subId: {
        filter: {
          id: 'show-sub-id',
          label: 'Sub ID',
          state: false,
          visible: false,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'subid-slot',
          title: 'Sub ID',
          sortField: 'subId',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      sub1: {
        filter: {
          id: 'show-sub1',
          label: 'Sub1',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'sub1',
          title: 'Sub1',
          sortField: 'sub1',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      sub2: {
        filter: {
          id: 'show-sub2',
          label: 'Sub2',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'sub2',
          title: 'Sub2',
          sortField: 'sub2',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      sub3: {
        filter: {
          id: 'show-sub3',
          label: 'Sub3',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'sub3',
          title: 'Sub3',
          sortField: 'sub3',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      sub4: {
        filter: {
          id: 'show-sub4',
          label: 'Sub4',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'sub4',
          title: 'Sub4',
          sortField: 'sub4',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      sub5: {
        filter: {
          id: 'show-sub5',
          label: 'Sub5',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'sub5',
          title: 'Sub5',
          sortField: 'sub5',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      keyword: {
        filter: {
          id: 'show-keyword-id',
          label: 'Keyword',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'keyword-slot',
          title: 'Keyword',
          sortField: 'keyword',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      referralPayoutFrom: {
        filter: {
          id: 'show-referral-revenue-from',
          label: 'Referral Payout From',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'referralPayoutFrom',
          title: 'Referral Payout From',
          sortField: 'referralPayoutFrom',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      //   		rpa: {
      // filter: {
      //    			id: 'show-rpa',
      //    			label: 'RPA',
      //    			state: false,
      //    			visible: true,
      //    			disabled: false,
      //    			group: 'metrics'
      // },
      // 	column: {
      // 	name: 'rpa',
      // 	title: 'RPA',
      // 	sortField: 'rpa',
      // 	callback: '',
      // 	dataClass: 'data-metric',
      // 	titleClass: '',
      // 	visible: true,
      // 	callback: '$formatMoney'
      // }
      //   		},
      //   		ppa: {
      // filter: {
      //    			id: 'show-ppa',
      //    			label: 'PPA',
      //    			state: false,
      //    			visible: true,
      //    			disabled: false,
      //    			group: 'metrics'
      // },
      // 	column: {
      // 	name: 'ppa',
      // 	title: 'PPA',
      // 	sortField: 'ppa',
      // 	callback: '',
      // 	dataClass: 'data-metric',
      // 	titleClass: '',
      // 	visible: true,
      // 	callback: '$formatMoney'
      // }
      //   		},
      //   		cpm: {
      // filter: {
      //    			id: 'show-cpm',
      //    			label: 'CPM',
      //    			state: false,
      //    			visible: true,
      //    			disabled: false,
      //    			group: 'metrics'
      // },
      // 	column: {
      // 	name: 'cpm',
      // 	title: 'CPM',
      // 	sortField: 'cpm',
      // 	callback: '',
      // 	dataClass: 'data-metric',
      // 	titleClass: '',
      // 	visible: true,
      // 	callback: '$formatMoney'
      // }
      //   		},
      //uniques: {
      //  filter: {
      //    id: 'show-uniques',
      //    label: 'Uniques',
      //    state: false,
      //    visible: true,
      //    disabled: false,
      //    group: 'metrics'
      //  },
      //  column: {
      //    name: 'uniques',
      //    title: 'Uniques',
      //    sortField: 'uniques',
      //    formatter: value => {
      //      return this.$formatInt(value)
      //    },
      //    dataClass: 'data-metric',
      //    titleClass: '',
      //    visible: true,
      //    defSortDirection: 'desc'
      //  }
      //},
      visits: {
        filter: {
          id: 'show-visits',
          label: 'Impressions',
          state: true,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'visits',
          title: 'Impressions',
          sortField: 'visits',
          formatter: value => {
            return this.$formatInt(value)
          },
          callback: '$formatInt',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          defSortDirection: 'desc'
        }
      },
      ppmActions: {
        filter: {
          id: 'show-ppm-actions',
          label: 'PPM Actions',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'ppmActions',
          title: 'PPM Actions',
          sortField: 'ppm-actions',
          formatter: value => {
            return this.$formatInt(value)
          },
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          defSortDirection: 'desc'
        }
      },
      cpcActions: {
        filter: {
          id: 'show-cpc-actions',
          label: 'Cpc Actions',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'cpcActions',
          title: 'Cpc Actions',
          sortField: 'cpcActions',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatInt(value)
          },
          defSortDirection: 'desc'
        }
      },
      aggregateViews: {
        filter: {
          id: 'show-aggregate-views',
          label: 'Aggregate Views',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'aggregateViews',
          title: 'Aggregate Views',
          sortField: 'aggregateViews',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatInt(value)
          },
          defSortDirection: 'desc'
        }
      },
      pageviews: {
        filter: {
          id: 'show-pageviews',
          label: 'Page Views',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'pageviews',
          title: 'Page Views',
          sortField: 'pageviews',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatInt(value)
          },
          defSortDirection: 'desc'
        }
      },
      clicks: {
        filter: {
          id: 'show-clicks',
          label: 'Clicks',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'clicks',
          title: 'Clicks',
          sortField: 'clicks',
          formatter: value => {
            return this.$formatInt(value)
          },
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          defSortDirection: 'desc'
        }
      },
      conversions: {
        filter: {
          id: 'show-conversions',
          label: 'Conversions',
          state: true,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'conversions',
          title: 'Conversions',
          sortField: 'conversions',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatInt(value)
          },
          defSortDirection: 'desc'
        }
      },

      yLeaderConversions: {
        filter: {
          id: 'show-y-leader-conversions',
          label: 'yConversions',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'yLeaderConversions',
          title: 'yConversions',
          sortField: 'yLeaderConversions',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatInt(value)
          },
          defSortDirection: 'desc'
        }
      },
      publisherConversions: {
        filter: {
          id: 'show-conversions',
          label: 'Publisher Conversions',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'publisherConversions',
          title: 'Publisher Conversions',
          sortField: 'publisherConversions',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatInt(value)
          },
          defSortDirection: 'desc'
        }
      },
      conversionRate: {
        filter: {
          id: 'show-conversions-rate',
          label: 'CR% - Conversion Rate',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'conversionRate',
          title: 'CR%',
          sortField: 'conversionRate',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatPercent(value, 2)
          },
          defSortDirection: 'desc'
        }
      },
      //   		adjustments_count: {
      // filter: {
      //    			id: 'show-adjustments-count',
      //    			label: 'Adjustments (Count)',
      //    			state: false,
      //    			visible: true,
      //    			disabled: false,
      //    			group: 'metrics'
      // },
      // 	column: {
      // 	name: 'adjustments_count',
      // 	title: 'Adjustments (Count)',
      // 	sortField: 'adjustments_count',
      // 	dataClass: 'data-metric',
      // 	titleClass: '',
      // 	visible: true,
      // 	callback: '$formatInt',
      // 	defSortDirection: 'desc'
      // }
      //   		},
      //   		adjustments_value: {
      // filter: {
      //    			id: 'show-adjustments-value',
      //    			label: 'Adjustments (Value)',
      //    			state: false,
      //    			visible: true,
      //    			disabled: false,
      //    			group: 'metrics'
      // },
      // 	column: {
      // 	name: 'adjustments_value',
      // 	title: 'Adjustments (Value)',
      // 	sortField: 'adjustments_value',
      // 	dataClass: 'data-metric',
      // 	titleClass: '',
      // 	visible: true,
      // 	callback: '$formatMoney',
      // 	defSortDirection: 'desc'
      // }
      //   		},
      ctr: {
        filter: {
          id: 'show-ctr',
          label: 'CTR',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'ctr',
          title: 'CTR',
          sortField: 'ctr',
          callback: '',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatPercent(value, 2)
          },
          defSortDirection: 'desc'
        }
      },
      rpa: {
        filter: {
          id: 'show-rpa',
          label: 'RPA',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'rpa',
          title: 'RPA',
          sortField: 'rpa',
          callback: '',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatMoney(value)
          },
          defSortDirection: 'desc'
        }
      },
      ppa: {
        filter: {
          id: 'show-ppa',
          label: 'PPA',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'ppa',
          title: 'PPA',
          sortField: 'ppa',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatMoney(value)
          },
          defSortDirection: 'desc'
        }
      },
      rpm: {
        filter: {
          id: 'show-rpm',
          label: 'RPM',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'rpm',
          title: 'RPM',
          sortField: 'rpm',
          callback: '',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatMoney(value)
          },
          defSortDirection: 'desc'
        }
      },
      epc: {
        filter: {
          id: 'show-epc',
          label: 'EPC',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'epc',
          title: 'EPC',
          sortField: 'epc',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatMoney(value)
          },
          defSortDirection: 'desc'
        }
      },
      yEpc: {
        filter: {
          id: 'show-y-epc',
          label: 'yEPC',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'yEpc',
          title: 'yEPC',
          sortField: 'yEpc',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatMoney(value)
          },
          defSortDirection: 'desc'
        }
      },
      yCpl: {
        filter: {
          id: 'show-y-cpl',
          label: 'yCPL',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'yCpl',
          title: 'yCPL',
          sortField: 'yCpl',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatMoney(value)
          },
          defSortDirection: 'desc'
        }
      },
      adminEcpm: {
        filter: {
          id: 'show-admin-ecpm',
          label: 'eCPM',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'adminEcpm',
          title: 'eCPM',
          sortField: 'adminEcpm',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatMoney(value)
          },
          defSortDirection: 'desc'
        }
      },
      ecpm: {
        filter: {
          id: 'show-ecpm',
          label: 'Publisher eCPM',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'ecpm',
          title: 'Publisher eCPM',
          sortField: 'ecpm',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatMoney(value)
          },
          defSortDirection: 'desc'
        }
      },
      payout: {
        filter: {
          id: 'show-payout',
          label: 'Payout',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'payout',
          title: 'Payout',
          sortField: 'payout',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatMoney(value)
          },
          defSortDirection: 'desc'
        }
      },
      //   		net: {
      // filter: {
      //    			id: 'show-net',
      //    			label: 'Net',
      //    			state: false,
      //    			visible: true,
      //    			disabled: false,
      //    			group: 'metrics'
      // },
      // 	column: {
      // 	name: 'net',
      // 	title: 'Net',
      // 	sortField: 'net',
      // 	callback: '',
      // 	dataClass: 'data-metric',
      // 	titleClass: '',
      // 	visible: true,
      // 	callback: '$formatMoney'
      // }
      //   		},
      payoutPercent: {
        filter: {
          id: 'show-payout-percent',
          label: 'Payout %',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'payoutPercent',
          title: 'Payout %',
          sortField: 'payoutPercent',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return value != 0 ? value + '%' : ''
          },
          defSortDirection: 'desc'
        }
      },
      realPayoutPercent: {
        filter: {
          id: 'show-real-payout-percent',
          label: 'Real Payout %',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'realPayoutPercent',
          title: 'Real Payout %',
          sortField: 'realPayoutPercent',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return value != 0 ? value + '%' : ''
          },
          defSortDirection: 'desc'
        }
      },
      yPayoutPercent: {
        filter: {
          id: 'show-y-payout-percent',
          label: 'yPayout %',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'yPayoutPercent',
          title: 'yPayout %',
          sortField: 'yPayoutPercent',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return value != 0 ? value + '%' : ''
          },
          defSortDirection: 'desc'
        }
      },
      revenue: {
        filter: {
          id: 'show-revenue',
          label: 'Revenue',
          state: true,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'revenue',
          title: 'Revenue',
          sortField: 'revenue',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatMoney(value)
          },
          defSortDirection: 'desc'
        }
      },
      yLeaderRevenue: {
        filter: {
          id: 'show-yleader-revenue',
          label: 'yRevenue',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'yLeaderRevenue',
          title: 'yRevenue',
          sortField: 'yLeaderRevenue',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatMoney(value)
          },
          defSortDirection: 'desc'
        }
      },
      revshareRevenue: {
        filter: {
          id: 'show-revshare-revenue',
          label: 'Revshare Revenue',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'revshareRevenue',
          title: 'Revshare Revenue',
          sortField: 'revshareRevenue',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatMoney(value)
          },
          defSortDirection: 'desc'
        }
      },
      revshareDepositValue: {
        filter: {
          id: 'show-revshare-deposit-value',
          label: 'Deposits',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'revshareDepositValue',
          title: 'Deposits',
          sortField: 'revshareDepositValue',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatMoney(value)
          },
          defSortDirection: 'desc'
        }
      },
      ftdAmount: {
        filter: {
          id: 'show-ftd-amount',
          label: 'FTD Amount',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'ftdAmount',
          title: 'FTD Amount',
          sortField: 'ftdAmount',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatMoney(value)
          },
          defSortDirection: 'desc'
        }
      },
      ftdCount: {
        filter: {
          id: 'show-ftd-count',
          label: 'First Time Deposit Count',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'ftdCount',
          title: 'First Time Deposit Count',
          sortField: 'ftdCount',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatInt(value)
          },
          defSortDirection: 'desc'
        }
      },
      qualifiedFtdCount: {
        filter: {
          id: 'show-ftd-count',
          label: 'Qualified First Time Deposit Count',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'qualifiedFtdCount',
          title: 'Qualified First Time Deposit Count',
          sortField: 'qualifiedFtdCount',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatInt(value)
          },
          defSortDirection: 'desc'
        }
      },
      registrationsCount: {
        filter: {
          id: 'show-registrations-count',
          label: 'Registrations Count',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'registrationsCount',
          title: 'Registrations Count',
          sortField: 'registrationsCount',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatInt(value)
          },
          defSortDirection: 'desc'
        }
      },
      realRevenue: {
        filter: {
          id: 'show-real-revenue',
          label: 'Real Revenue',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'realRevenue',
          title: 'Real Revenue',
          sortField: 'realRevenue',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatMoney(value)
          },
          defSortDirection: 'desc'
        }
      },
      netRevenue: {
        filter: {
          id: 'show-net-revenue',
          label: 'Net Revenue',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'netRevenue',
          title: 'Net Revenue',
          sortField: 'netRevenue',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatMoney(value)
          },
          defSortDirection: 'desc'
        }
      },
      adjustments: {
        filter: {
          id: 'show-adjustments',
          label: 'Adjustments',
          state: false,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'adjustments',
          title: 'Adjustments',
          sortField: 'adjustments',
          dataClass: 'data-metric',
          formatter: value => {
            return this.$formatInt(value)
          },
          titleClass: '',
          visible: true,
          defSortDirection: 'desc'
        }
      }
    }
    let columns = this.createVuetableColumns(masterColumnData)
    return {
      dataTotal: 0,
      totals: {},
      vuetableData: null,
      loadTimes: null,
      masterColumnData: masterColumnData,
      fields: columns,
      hasEverLoaded: false,
      css: {
        tableClass:
          'table table-sm table-striped dt-responsive nowrap dataTable',
        loadingClass: 'loading',
        ascendingIcon: 'custom-table-sort-arrow-up',
        descendingIcon: 'custom-table-sort-arrow-down',
        detailRowClass: 'vuetable-detail-row',
        sortHandleIcon: 'grey sidebar icon'
      },
      sortOrder: [
        {
          field: 'revenue',
          sortField: 'revenue',
          direction: 'desc'
        }
      ],
      isExporting: false,
      multiSort: true,
      perPage: 12,
      isLoading: false,
      startTimeDefault: '00:00',
      endTimeDefault: '23:59',
      endTimeKey: 'endTimeKey',
      startTimeKey: 'startTimeKey',
      isMounted: false,
      startDateTime: null,
      endDateTime: null
    }
  },
  watch: {
    sortOrder: function(oldSortOrder, newSortOrder) {
      if (!this.isLoading) {
        this.$events.emit('sortReportRows', newSortOrder)
      }
    },
    isLoading: function(value) {
      if (value == true) {
        this.hasEverLoaded = true
      }
    }
  },
  mounted() {
    this.isMounted = true
  },
  updated() {},
  computed: {
    readableDateRange() {
      if (!this.startDateTime || !this.endDateTime) {
        return
      }
      return `${this.startDateTime} - ${this.endDateTime}`
    }
  },
  methods: {
    cleanApolloData(data) {
      let output = data
      output = this.$omitDeep(output, '__typename')
      output = this.$omitDeep(output, 'Symbol(id)')
      output = JSON.parse(JSON.stringify(output))
      return output
    },
    exportData() {
      this.isExporting = true
      saveCsvString(
        objectToCsv(this.cleanApolloData(this.vuetableData)),
        this.readableDateRange + '.csv'
      )
      this.isExporting = false
    },
    formatTotalField(field) {
      let output = ''
      if (this.totals[field.name]) {
        output = field.formatter(this.totals[field.name])
      }
      return output
    },
    createVuetableColumns(master) {
      var vuetableColumns = []
      for (var column in master) {
        if (master[column].filter.state == true) {
          vuetableColumns.push(master[column].column)
        }
      }
      return vuetableColumns
    },
    onPaginationData() {
      // this.$refs.pagination.setPaginationData(paginationData)
      // this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    onChangePage(page) {
      this.$events.emit('changeReportPage', page)
    },
    onLoading() {
      this.isLoading = true
    },
    onLoaded() {
      this.isLoading = false
    }
  }
}
</script>
<style>
.template-column {
  max-width: 300px !important;
  overflow: hidden;
}
</style>
