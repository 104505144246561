<template>
  <div id="top">
    <Widget class="mb-2">
      <WidgetHeader class="bg-gray-200 p-1">
        <template slot="rawContent">
          <div class="w-full">
            <div
              class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-5 gap-1"
            >
              <YInput v-model="filters.id.value" label="ID"></YInput>
              <YInput v-model="filters.name.value" label="Name"></YInput>
              <Superselect
                title="Subscribers"
                v-model="filters.subscribers.value"
                :modifiers="filters.subscribers.modifier"
                :options="[]"
                placeholder="Select"
                track-by="label"
                label="label"
                :close-on-select="false"
                :query="PUBLISHER_OPTIONS_QUERY"
                :query-variables="{
                  first: 999,
                  filters: {
                    label: {
                      value: ['{input}'],
                      modifiers: { matchType: 'contains' }
                    }
                  }
                }"
                edit-placeholder="Paste Publishers"
              ></Superselect>
              <Superselect
                title="Advertiser"
                v-model="filters.advertisers.value"
                placeholder="Advertiser"
                label="label"
                track-by="advertiserId"
                :options="filterOptions.advertisers"
              ></Superselect>
              <Superselect
                title="Payout Type"
                v-model="filters.payoutType.value"
                placeholder="Payout Type"
                :multiple="false"
                :options="filterOptions.payoutType"
              ></Superselect>
              <Superselect
                title="Countries"
                v-model="filters.countries.value"
                placeholder="Countries"
                track-by="id"
                label="label"
                :options="filterOptions.countries"
              ></Superselect>

              <Superselect
                title="Vertical"
                v-model="filters.verticals.value"
                placeholder="Verticals"
                :options="filterOptions.verticals"
              ></Superselect>
              <Superselect
                title="Offer Type"
                v-model="filters.isSmartLink.value"
                placeholder="Offer Type"
                :options="filterOptions.isSmartLink"
              ></Superselect>

              <Superselect
                title="Traffic Type"
                v-model="filters.trafficType.value"
                :modifiers.sync="filters.trafficType.modifiers"
                placeholder="Traffic Type"
                :options="filterOptions.trafficType"
              ></Superselect>

              <Superselect
                title="Access Rules"
                v-model="filters.accessRules.value"
                :options="[{ id: 'publishers', label: 'All Publishers' }]"
                placeholder="Select"
                track-by="label"
                label="label"
                :close-on-select="false"
                :query="PUBLISHER_OPTIONS_QUERY"
                :query-variables="{
                  first: 999,
                  filters: {
                    label: {
                      value: ['{input}'],
                      modifiers: { matchType: 'contains' }
                    }
                  }
                }"
                :query-result-function="
                  data => {
                    let options = data.publisherOptions.data
                    options = [
                      { id: 'publishers', label: 'All Publishers' },
                      ...options
                    ]
                    return options
                  }
                "
                edit-placeholder="Paste  Publishers"
              ></Superselect>

              <div
                class="flex content-center flex-col mt-1 row-span-2 lg:row-span-1"
              >
                <div class="pl-2">
                  <label class="inline-flex items-center">
                    <input
                      type="checkbox"
                      id="checkbox"
                      v-model="filters.isFeatured.value"
                      class="w-4 h-4 rounded"
                    />
                    <span class="ml-2">Featured Campaigns</span>
                  </label>
                </div>

                <div class=" mx-1 ">
                  <button
                    @click="showPinned = !showPinned"
                    class="transition-all mr-2"
                  >
                    <span v-if="showPinned">
                      <Icon
                        name="toggleSwitchOn"
                        :size="12"
                        class="inline-block text-blue-600 "
                      />
                    </span>
                    <span v-else>
                      <Icon
                        name="toggleSwitchOff"
                        :size="12"
                        class="inline-block text-gray-500"
                    /></span>
                  </button>
                  <span v-if="showPinned">Hide Pinned</span>
                  <span v-if="!showPinned">Show Pinned</span>
                </div>
              </div>

              <div class="hidden lg:block"></div>
              <div class="hidden lg:block"></div>
              <Superselect
                title="Status"
                v-model="filters.status.value"
                placeholder="Select Status"
                :options="filterOptions.status"
              ></Superselect>
              <Superselect
                title="Order By"
                v-model="orderBy"
                placeholder
                :allow-empty="false"
                :multiple="false"
                :options="orderByOptions"
                track-by="label"
                label="label"
              ></Superselect>
            </div>
          </div>
        </template>
      </WidgetHeader>
    </Widget>

    <div>
      <transition name="slide">
        <PinnedCampaignsTable v-if="showPinned"></PinnedCampaignsTable>
      </transition>
    </div>

    <AdminCampaignsTable
      class="mt-3"
      :data="campaigns ? campaigns.data : []"
      :is-loading="$apollo.queries.campaigns.loading"
    ></AdminCampaignsTable>

    <YPagination
      v-if="campaigns.paginatorInfo"
      :data="campaigns.paginatorInfo"
      :onEachSide="4"
      :key="totalPages"
      @load-page="loadPage"
      @next-page="nextPage"
      @previous-page="previousPage"
      @per-page="perPageSelection"
    ></YPagination>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import AdminCampaignsTable from './AdminCampaignsTable'
import PinnedCampaignsTable from '@/views/Admin/Campaigns/PinnedCampaignsTable.vue'
import CAMPAIGNS_QUERY from '@/graphql/Campaign/CampaignsQuery.gql'
import PUBLISHER_OPTIONS_QUERY from '@/graphql/Publisher/PublisherOptionsQuery.gql'
import TAGS_QUERY from '@/graphql/Tag/TagsQuery.gql'
import BackToTop from '@/mixins/BackToTopMixin.js'
import YPagination from '@/components/ui/YPagination.vue'
export default {
  mixins: [BackToTop],
  components: {
    AdminCampaignsTable,
    YPagination,
    PinnedCampaignsTable
  },
  apollo: {
    campaigns: {
      query: CAMPAIGNS_QUERY,
      debounce: 250,
      watchLoading(isLoading, countModifier) {
        this.$store.dispatch('updateGlobalLoader', countModifier)
      },
      variables() {
        return {
          filters: this.campaignsQueryFilters,
          first: this.perPage,
          orderBy: this.gqlOrderBy,
          page: this.currentPage
        }
      },
      result({ data }, key) {
        this.totalPages = data[key].paginatorInfo.lastPage
      },
      deep: true
    },
    countries: {
      query: gql`
        {
          countries {
            id: code
            code
            name
            label
          }
        }
      `,
      result: function(result) {
        this.filterOptions.countries = result.data.countries
      }
    },
    verticalTags: {
      query: TAGS_QUERY,
      update: data => data.tags,
      variables() {
        return {
          first: 999,
          page: this.currentPage,
          filters: {
            type: {
              value: ['vertical']
            }
          }
        }
      },
      result: function(result) {
        this.filterOptions.verticals = result.data.tags.data.map(
          tag => tag.name
        )
      }
    },
    trafficTypeTags: {
      query: TAGS_QUERY,
      update: data => data.tags,
      variables() {
        return {
          first: 999,
          page: this.currentPage,
          filters: {
            type: {
              value: ['trafficType']
            }
          }
        }
      },
      result: function(result) {
        this.filterOptions.trafficType = result.data.tags.data.map(
          tag => tag.name
        )
      }
    },
    advertisers: {
      query: gql`
        query Advertisers($filters: UserFiltersInput, $first: Int!) {
          advertisers: users(filters: $filters, first: $first) {
            data {
              id
              advertiserId
              label
              primaryContact {
                id
                company
                name
              }
              accountManagers {
                id
                primaryContact {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          filters: {
            roles: {
              name: { value: 'advertiser' }
            }
          },
          deep: true,
          first: 999
        }
      },
      result: function(result) {
        this.filterOptions.advertisers = result.data.advertisers.data
      }
    }
  },
  data() {
    return {
      showPinned: true,
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      PUBLISHER_OPTIONS_QUERY,
      inputsMinWidth: '15rem',
      campaigns: {},
      orderBy: {
        column: 'id',
        order: 'DESC',
        label: 'Newest'
      },
      orderByOptions: [
        {
          label: 'Newest',
          column: 'id',
          order: 'DESC'
        },
        {
          label: 'Oldest',
          column: 'id',
          order: 'ASC'
        }
      ],
      sortOrder: [],
      multiSort: false,
      isLoading: false,
      filters: {
        id: {
          value: null
        },
        name: {
          value: null,
          modifiers: {
            matchType: 'contains'
          }
        },
        subscribers: {
          value: null,
          modifiers: {
            matchType: 'exact'
          }
        },
        accessRules: {
          value: null
        },
        payoutType: {
          value: null,
          modifiers: {
            matchType: 'exact'
          }
        },
        advertisers: {
          value: null,
          modifiers: {
            matchType: 'exact'
          }
        },
        countries: {
          value: null,
          modifiers: {
            matchType: 'exact'
          }
        },
        verticals: {
          value: null,
          modifiers: {
            matchType: 'exact'
          }
        },
        trafficType: {
          value: null,
          modifiers: {
            isNot: false
          }
        },
        tags: {
          value: null,
          modifiers: {
            matchType: 'exact'
          }
        },
        status: {
          value: ['active'],
          modifiers: {
            matchType: 'exact'
          }
        },
        isSmartLink: {
          value: undefined
        },
        isFeatured: {
          value: false,
          modifiers: {
            matchType: 'contains'
          }
        }
      },
      filterOptions: {
        advertisers: [],
        payoutType: ['CPA', 'CPI', 'CPL', 'CPS', 'Revshare'],
        verticals: [],
        countries: [],
        status: ['active', 'paused'],
        subscribers: [],
        accessRules: [],
        isSmartLink: ['Single Offer', 'Smart Link'],
        trafficType: []
      }
    }
  },
  updated() {},
  computed: {
    campaignsQueryFilters() {
      let permissionsFilter =
        this.filters.accessRules && this.filters.accessRules.value
          ? {
              roles:
                this.filters.accessRules.value.filter(
                  item => item.id == 'publishers'
                ).length > 0
                  ? ['publisher']
                  : undefined,
              users: this.filters.accessRules.value
                .filter(item => item.id != 'publishers')
                .map(user => user.id)
            }
          : undefined
      return {
        id: this.cleanFilter(this.filters.id),
        offers: {
          advertiserIds: this.cleanObjectFilter(
            this.filters.advertisers,
            'advertiserId'
          )
        },
        name: this.cleanFilter(this.filters.name),
        payoutType: this.cleanFilter(this.filters.payoutType),
        countryCode: this.cleanObjectFilter(this.filters.countries, 'id'),
        //verticals: this.cleanObjectFilter(this.filters.verticals, 'name'),
        verticals: {
          value: this.filters.verticals.value
            ? this.filters.verticals.value.map(vertical => vertical)
            : null
        },
        isSmartLink:
          this.filters.isSmartLink.value == 'Smart Link'
            ? true
            : this.filters.isSmartLink.value == 'Single Offer'
            ? false
            : undefined,
        trafficType: this.cleanFilter(this.filters.trafficType),
        subscribedRedirects:
          this.filters.subscribers && this.filters.subscribers.value
            ? {
                userId: this.cleanObjectFilter(this.filters.subscribers, 'id')
              }
            : undefined,
        permissions: permissionsFilter,
        status:
          this.filters.status.value && this.filters.status.value.length > 0
            ? this.filters.status.value
            : undefined,
        isFeatured: this.filters.isFeatured.value
          ? {
              value: [''],
              modifiers: this.filters.isFeatured.modifiers
            }
          : undefined
      }
    },
    users() {
      let data = this.campaigns ? this.campaigns.data : []
      return data
    },
    activeFilters() {
      let output = this._.chain(this.filters)
        .mapValues(function(filter) {
          if (
            filter.value &&
            filter.value.constructor === Array &&
            filter.value.length == 0
          ) {
            return null
          } else if (filter.value) {
            return filter
          } else {
            return null
          }
        })
        .pickBy(this._.identity)
        .value()
      return this.cleanApolloData(output)
    },
    gqlOrderBy() {
      let orderBy = this._.clone(this.orderBy)
      delete orderBy.label
      return [orderBy]
    },
    paginationData() {
      return this.campaigns ? this.campaigns.paginatorInfo : null
    }
  },
  watch: {
    activeFilters: function() {
      this.page = 1
      this.showPinned = false
    },
    totalPages() {
      this.currentPage = 1
    }
  },
  mounted() {
    this.$events.listen('campaignDeleted', () => {
      this.$apollo.queries.campaigns.refetch()
    })
  },
  beforeDestroy() {
    this.$events.remove('campaignDeleted')
  },
  methods: {
    loadPage(page) {
      this.currentPage = page
      this.backToTop()
    },
    nextPage() {
      this.currentPage++
      this.backToTop()
    },
    previousPage() {
      this.currentPage--
      this.backToTop()
    },
    perPageSelection(value) {
      this.perPage = value
    },
    cleanApolloData(output) {
      output = JSON.parse(JSON.stringify(output))
      return output
    },
    cleanFilter(filter) {
      // Return undefined on null values
      // And make sure value is always an array
      return filter.value
        ? {
            value: Array.isArray(filter.value) ? filter.value : [filter.value],
            modifiers: filter.modifiers
          }
        : undefined
    },
    cleanObjectFilter(filter, pluck) {
      // defer to cleanFilter for undefined check and array cast
      if (this.cleanFilter(filter) === undefined) {
        return undefined
      }
      // pluck key from value
      return {
        value: this.cleanFilter(filter).value.map(value => value[pluck]),
        modifiers: filter.modifiers
      }
    },
    onLoading() {
      this.isLoading = true
    },
    onLoaded() {
      this.isLoading = false
    }
  }
}
</script>
<style scoped>
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}
.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
