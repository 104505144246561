<template>
  <widget>
    <widget-header @close="$emit('close')">
      Create New Advertiser
    </widget-header>
    <widget-body>
      <form class="row" @submit.prevent="update" ref="form">
        <div class="col">
          <div class="row">
            <div class="input-group" :class="colSize">
              <YInput v-model="advertiser.name" label="Name"></YInput>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="input-group" :class="colSize">
              <YInput
                v-model="advertiser.company"
                label="Billing Name"
              ></YInput>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="input-group" :class="colSize">
              <Superselect
                title="Manager"
                v-model="accountManager"
                :multiple="false"
                track-by="id"
                label="label"
                :query="ACCOUNT_MANAGER_OPTIONS_QUERY"
                :query-variables="{
                  filters: {
                    label: {
                      value: [],
                      modifiers: { matchType: 'contains' }
                    }
                  },
                  first: 25
                }"
              ></Superselect>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="input-group" :class="colSize">
              <YInput
                v-model="triggerValue"
                label="Monthly Revenue Cap"
                :required="true"
              ></YInput>
            </div>
          </div>
        </div>
      </form>
    </widget-body>
    <widget-footer class="px-4 py-2 bg-gray-100 text-right">
      <y-button
        class="btn btn-blue"
        :is-loading="isSubmitting"
        @click="create"
        @keydown.enter="create"
        >Create Account</y-button
      >
    </widget-footer>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </widget>
</template>

<script>
import gql from 'graphql-tag'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import ACCOUNT_MANAGER_OPTIONS_QUERY from '@/graphql/AccountManager/AccountManagerOptionsQuery.gql'
import UPSERT_CAPS_MUTATION from '@/graphql/Caps/UpsertCapsMutation.gql'

export default {
  mixins: [ValidationErrorsMixin],

  props: {},
  components: {},
  data() {
    return {
      ACCOUNT_MANAGER_OPTIONS_QUERY,
      advertiser: {},
      accountManager: null,
      isSubmitting: false,
      colSize: 'col-xl-3 col-lg-6',
      colSize2: 'col-xl-6 col-lg-12',
      yLeadtypeOptions: ['lead', 'real', 'none'],
      triggerValue: 1000
    }
  },
  watch: {},
  computed: {},
  methods: {
    create() {
      Number(this.triggerValue) > 0
        ? this.createAdvertiser()
        : this.$toastr.e(
            'Monthly Revenue Cap is required.',
            'Monthly Revenue Cap'
          )
    },
    createAdvertiser() {
      this.isSubmitting = true
      let input = {
        name: this.advertiser.name,
        company: this.advertiser.company,
        accountManager: this.accountManager ? this.accountManager.id : undefined
      }
      this.$apollo
        .mutate({
          mutation: gql`
            mutation createAdvertiser($input: CreateAdvertiserInput!) {
              createAdvertiser(input: $input) {
                id
              }
            }
          `,
          variables: {
            input: input
          }
        })
        .then(data => {
          this.clearValidationErrors()
          const advertiserId = data.data.createAdvertiser.id
          this.createAdvertiserCap(advertiserId)
        })
        .catch(error => {
          this.setValidationErrors(error)
          this.isSubmitting = true
        })
    },
    createAdvertiserCap(id) {
      this.$apollo
        .mutate({
          mutation: UPSERT_CAPS_MUTATION,
          variables: {
            input: [
              {
                frequency: 'monthly',
                type: 'revenue',
                cappableId: id,
                cappableType: 'Advertiser',
                triggerableId: null,
                triggerableType: null,
                triggerValue: Number(this.triggerValue)
              }
            ]
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.isSubmitting = true
          this.$toastr.s('Advertiser Created', 'Success!')
          this.$events.emit('refreshAdvertisers', true)
          this.$emit('close')
        })
        .catch(error => {
          this.setValidationErrors(error)
          console.error(error)
        })
    }
  },
  close() {
    this.$emit('close')
  },
  mounted() {},
  updated() {}
}
</script>
